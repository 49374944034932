import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ROLES } from 'config/constants'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { ERROR_TYPE_CONTENTFUL } from 'layers/errorHandling/apiError'
import withApiError from 'layers/errorHandling/apiError/component'
import { operations as userSessionOperations } from 'store/userSessionManager'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import { operations as licenseOperations } from 'store/licenseManager'
import AdminDashboardBanner from './component'
import { findProgramLinks } from 'utils/findProgramLinks'
import { Logger } from 'logging'

export const AdminDashboardBannerContainer = ({
  claims,
  displayTitle,
  fetchSetupLicensesForUser,
  isFetchingSetupLicenses,
  isLaunched,
  isSetupAdmin,
  hsToggleView,
  k8ToggleView,
  navigationEvent,
  onChangeProgram,
  onboardingSnapshot,
  programQuickLinks,
  programTitles,
  selectedProgram,
  setHSToggleView,
  setK8ToggleView,
  unassignedLicenses,
}) => {
  useEffect(() => {
    if (isSetupAdmin && !isFetchingSetupLicenses && !unassignedLicenses)
      fetchSetupLicensesForUser()
  }, [])

  const getAvailableLinks = () => {
    if (!programQuickLinks || !selectedProgram) {
      return
    }

    const {
      hasBpuAdminClaim,
      hasCpuAdminClaim,
      hasElemAdminClaim,
      hasHighschoolAdminClaim,
      hasMsAdminClaim,
      hasSelaAdminClaim,
    } = claims

    const programAccessMap = {
      BPU: { access: hasBpuAdminClaim, program: 'bpuDigital' },
      CPU: { access: hasCpuAdminClaim, program: 'cpuDigital' },
      HS: { access: hasHighschoolAdminClaim, program: 'highSchool' },
      K8: {
        access: hasElemAdminClaim && hasMsAdminClaim,
        k5program: 'k5SelDigital',
        msprogram: 'mspDigital',
      },
      SELA: { access: hasSelaAdminClaim, program: 'sela' },
    }

    return programAccessMap?.[selectedProgram.name]?.access
      ? findProgramLinks(programQuickLinks, selectedProgram, programAccessMap)
      : []
  }

  const handleKeyPress = (e, view) => {
    if (e.key === 'Enter') {
      handleTabChange(view)
    }
  }

  const handleTabChange = view => {
    let pagedViewUrl = `/admin/program/${selectedProgram.key.toLowerCase()}/`
    let viewOption = 'program_adoption'
    if (selectedProgram.key === 'K8') {
      setK8ToggleView(view)
    }
    if (selectedProgram.key === 'HS') {
      setHSToggleView(view)
    }
    if (view === TOGGLE_VIEW.LAUNCH) {
      navigationEvent(viewOption, 'REPLACE')
    } else {
      viewOption = 'monitor'
      navigationEvent(viewOption, 'REPLACE')
    }

    try {
      pagedViewUrl += viewOption
      Logger.logEvent({
        name: pagedViewUrl,
        properties: { viewOption },
      })
    } catch (e) {
      console.error('Cannot log Admin toggle event')
    }
  }

  return (
    <AdminDashboardBanner
      availableLinks={getAvailableLinks()}
      displayTitle={displayTitle}
      handleKeyPress={handleKeyPress}
      handleTabChange={handleTabChange}
      hsToggleView={hsToggleView}
      isLaunched={isLaunched}
      k8ToggleView={k8ToggleView}
      navigationEvent={navigationEvent}
      onChangeProgram={onChangeProgram}
      onboardingSnapshot={onboardingSnapshot}
      programTitles={programTitles}
      selectedProgram={selectedProgram}
      unassignedLicenses={unassignedLicenses}
    />
  )
}

AdminDashboardBannerContainer.propTypes = {
  claims: PropTypes.object,
  displayTitle: PropTypes.string,
  fetchSetupLicensesForUser: PropTypes.func,
  hsToggleView: PropTypes.string,
  isFetchingSetupLicenses: PropTypes.bool,
  isLaunched: PropTypes.bool,
  isSetupAdmin: PropTypes.bool,
  k8ToggleView: PropTypes.string,
  navigationEvent: PropTypes.func,
  onChangeProgram: PropTypes.func,
  onboardingSnapshot: PropTypes.array,
  programQuickLinks: PropTypes.array,
  programTitles: PropTypes.array,
  selectedProgram: PropTypes.object,
  setHSToggleView: PropTypes.func,
  setK8ToggleView: PropTypes.func,
  unassignedLicenses: PropTypes.array,
}

const mapStateToProps = ({
  reportsManager,
  userAccessManager,
  licenseManager,
  userSessionManager: {
    adminDashboard: {
      hs: { toggleView: hsToggleView },
      k8: { toggleView: k8ToggleView },
    },
  },
  userProfileManager,
}) => {
  const { selectedProgram, programTitles, programs } = reportsManager || {}
  const { claims } = userAccessManager || {}
  const { unassignedLicenses, isFetchingSetupLicenses } = licenseManager || {}
  const {
    profile: { roles },
  } = userProfileManager || {}
  const isSetupAdmin = roles?.includes(ROLES.SETUP_ADMIN)
  const unassignedSetupLicenses = isSetupAdmin ? unassignedLicenses : []

  return {
    claims,
    hsToggleView,
    isFetchingSetupLicenses,
    isSetupAdmin,
    k8ToggleView,
    programs,
    programTitles,
    selectedProgram,
    unassignedLicenses: unassignedSetupLicenses,
  }
}

const mapDispatchToProps = {
  fetchSetupLicensesForUser: licenseOperations.fetchSetupLicensesForUser,
  setHSToggleView: userSessionOperations.adminSetHSToggleView,
  setK8ToggleView: userSessionOperations.adminSetK8ToggleView,
}

const mapper = entry => {
  const { displayTitle, programQuickLinks } = entry || {}
  return {
    displayTitle,
    programQuickLinks,
  }
}

const options = {
  entryId: '5oqdbnI289AADFVmm2sJyx',
  include: 3,
  mapper,
  spread: true,
}

export default ContentEntryDataGetter(
  withApiError(AdminDashboardBannerContainer, [ERROR_TYPE_CONTENTFUL]),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
