/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import { connect } from 'react-redux'

import ParseEntry from 'utils/contentfulParser'
import { RouteContentProductAppMap } from 'utils/productMaps'
import { isPreviewMode } from 'utils/highschoolHelpers'
import { isFlagOn } from 'utils/featureFlags'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import withApiError from 'layers/errorHandling/apiError'
import { selectors as lmsSelectors } from 'store/lmsManager'
import ResourcesContent from './component'
import { determinePageLayout } from './pageLayouts'
import { getCompletedTrainingProgramNodesCount } from './helpers'

const ResourcesContentContainer = props => {
  const {
    children,
    getEntryWithNewLocale,
    isDistrictSupportsPage,
    isFamilyResourcesPage,
    isSelaLeaderPage,
    isSelaProduct,
    match,
    routeContent,
    highSchoolInstructorData,
  } = props || {}

  const { content = {}, route = {}, loading } = routeContent
  const { courseNodeId = '' } = route || {}
  const { article } = content
  let parsedEntry

  if (content) {
    parsedEntry = mapper(article) || {}
  }

  const readyToLoad = !!content && !loading
  const numberOfCompletedTrainingPrograms = getCompletedTrainingProgramNodesCount(
    highSchoolInstructorData,
  )

  return (
    readyToLoad && (
      <ResourcesContent
        {...parsedEntry}
        courseNodeId={courseNodeId}
        getEntryWithNewLocale={getEntryWithNewLocale}
        isDistrictSupportsPage={isDistrictSupportsPage}
        isFamilyResourcesPage={isFamilyResourcesPage}
        isHsPreview={isPreviewMode()}
        isSelaLeaderPage={isSelaLeaderPage}
        isSelaProduct={isSelaProduct}
        match={match}
        numberOfCompletedTrainingPrograms={numberOfCompletedTrainingPrograms}
      >
        {children}
      </ResourcesContent>
    )
  )
}

const mapper = entry => {
  if (entry) {
    const { markComplete } = entry || {}
    const { derivedContent, rootContent, content } = ParseEntry(
      entry,
      isPreviewMode(),
      isFlagOn(['high-school-june-release']),
    )
    const { anchorNavigation } = derivedContent || {}
    const { landingBanner } = rootContent || {}
    const pageLayout = determinePageLayout(anchorNavigation)
    const currentLocale = entry && entry.locale
    entry = {
      anchorNavigation,
      content,
      landingBanner,
      markComplete,
      pageLayout,
      currentLocale,
    }

    return entry
  }

  return null
}

const mapStateToProps = state => ({
  highSchoolInstructorData: lmsSelectors.selectHsInstructorData(state),
})

const options = {
  app: ({ route }) =>
    route.includes('family-resources')
      ? RouteContentProductAppMap.learnOrphans
      : RouteContentProductAppMap.learn,
  routeResolver: () => window?.location?.pathname,
}
const ConnectedResourcesContentContainer = connect(mapStateToProps)(
  ResourcesContentContainer,
)

export default RouteContentGetter(
  withApiError(ConnectedResourcesContentContainer),
  options,
)
