import { connect } from 'react-redux'

import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError from 'layers/errorHandling/apiError'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import { resolveRouteServiceApp } from 'utils/productHelpers'

import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'

import Component from './component'

const mapStateToProps = state => {
  return {
    userSegmentationInfo: userSegmentationBuilder(state),
  }
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)
const loadingConnect = connect(mapStateToProps)

const options = {
  app: ({ route }) => resolveRouteServiceApp(route),
  routeResolver: () => window?.location?.pathname,
}

export default RouteContentGetter(
  withApiError(loadingConnect(navigationConnect(Component))),
  options,
)
