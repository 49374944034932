import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LoadingParent from 'components/LoadingParent'
import { Main } from './SecondStepLayout.styles'
import { Grommet } from 'grommet'
import Navigation from '../experienceComponents/Navigation'
import Footer from 'components/Footer'
import { getThemeFromUrl } from 'utils/productHelpers'
import { useLocation } from 'react-router'

export const SecondStepLayout = ({
  children,
  isLoadingApp,
  globalLoadingText,
}) => {
  const [showFooter, setShowFooter] = useState(false)
  const { pathname } = useLocation()
  let interval = null
  // TODO: We should update all of our loading pages to use the global loading manager and then we won't need this
  useEffect(() => {
    if (pathname && !showFooter) {
      interval = setInterval(() => {
        const anyFullScreenLoader = document.querySelector(
          '[data-testid="loading-logo"]',
        )
        setShowFooter(!anyFullScreenLoader)
      })
    }

    if (showFooter) {
      clearInterval(interval)
    }
  }, [showFooter, pathname])

  // TODO: This should be a top level secondstep theme???? IE Dashboard theme?
  const ssTheme = getThemeFromUrl()
  return (
    <Grommet
      style={{
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100vh',
      }}
      theme={ssTheme}
    >
      <Navigation />
      {isLoadingApp && <LoadingParent loadingText={globalLoadingText} />}
      <Main id="main-content">{children}</Main>
      {showFooter && <Footer />}{' '}
    </Grommet>
  )
}

export const mapStateToProps = ({ loadingManager }) => {
  return {
    isLoadingApp: loadingManager?.isLoading,
    globalLoadingText: loadingManager?.loadingTextState,
  }
}

SecondStepLayout.propTypes = {
  children: PropTypes.node,
  globalLoadingText: PropTypes.string,
  isLoadingApp: PropTypes.bool,
}
export default connect(mapStateToProps, null)(SecondStepLayout)
