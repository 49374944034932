import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import {
  formatModuleFileTitle,
  formatUnitFileTitle,
} from 'utils/selaReportsHelpers'
import pathwayNameMap from './pathwayNameMap'
import typeHeaderMap from './typeHeaderMap'
import {
  compare,
  createDataForMatchingDetail,
  createDataForNoMatchingDetail,
  createChoiceCurriculumHeaders,
  createModuleOrUnitHeaders,
  sortGrades,
} from './helpers'
import {
  REPORTING_UNIT_SUMMARY_TO_LEARN_GRADES,
  SORTED_GRADE_IDX,
  MAX_UNITS_PER_CLASS,
} from './constants'
import { createSelector } from 'reselect'
import { defaultGrades } from './defaultGrades'
import { selectors as licenseSelectors } from 'store/licenseManager'
import { selectors as userContextSelectors } from 'auth/stores/userContext'
import { isFlagOn } from 'utils/featureFlags'

export const createCsvData = (
  userDetails,
  contentfulId,
  moduleLabel,
  moduleTitle,
  unitLabel,
  schoolName,
  type,
  programTrack,
) => {
  const isFieldTest = !!programTrack?.includes('selaft')

  if (!typeHeaderMap[type] || !userDetails) return

  const { headers: typeHeaders } = typeHeaderMap[type]
  const createdHeaders = createHeaders(
    userDetails,
    programTrack,
    typeHeaders,
    contentfulId,
  )
  const createdData = createData(
    userDetails,
    programTrack,
    createdHeaders,
    contentfulId,
    isFieldTest,
  )
  const createdFilename = createFilename(
    userDetails,
    schoolName,
    contentfulId,
    moduleLabel,
    moduleTitle,
    programTrack,
    unitLabel,
    type,
  )

  return {
    data: createdData,
    filename: createdFilename,
    headers: createdHeaders,
  }
}

export const createHeaders = (
  userDetails,
  programTrack,
  existingHeaders,
  contentfulId,
) => {
  let createdHeaders = [...existingHeaders]

  userDetails.forEach(user => {
    if (programTrack !== user.content_track) return
    const matchingDetails = findDetailsById(user.user_children, contentfulId)
    const { type: detailType } = matchingDetails

    matchingDetails?.user_children.forEach((detail, i) => {
      const dataExists = !!detail.display_title && !!detail.type
      const newHeaderKey = detail.display_title

      if (dataExists) {
        if (detail.type === 'choiceCurriculum') {
          createChoiceCurriculumHeaders(detail, createdHeaders)
        } else {
          createModuleOrUnitHeaders(
            createdHeaders,
            detail,
            detailType,
            i,
            newHeaderKey,
          )
        }
      }
    })
  })

  return createdHeaders
}

export const createData = (
  userDetails,
  contentTrack,
  headers,
  contentfulId,
  isFieldTest,
) => {
  const createdData = []

  // Using passed content track (sela leader VS sela staff) to determine what role needs to be checked from all the users
  const role = contentTrack.includes('staff') ? 'staff' : 'leader'
  const userRoleCheck = new RegExp(role, 'gi')

  userDetails?.forEach(user => {
    if (!isFieldTest && (user.role?.match(userRoleCheck) || []).length === 0)
      return
    const matchingDetail = findDetailsById(user.user_children, contentfulId)

    if (matchingDetail) {
      createDataForMatchingDetail(createdData, matchingDetail, user)
    } else {
      createDataForNoMatchingDetail(createdData, headers, user)
    }
  })

  createdData.sort(compare)
  return createdData
}

export const createFilename = (
  userDetails,
  schoolName,
  contentfulId,
  moduleLabel,
  moduleTitle,
  programTrack,
  unitLabel,
  type,
) => {
  const matchingDetails = findDetailsById(userDetails, contentfulId)
  const formattedDate = moment().format('ll')
  const title = matchingDetails?.display_title
  const role = programTrack.includes('staff') ? 'Staff' : 'Leader'

  let moduleOrUnitTitle
  if (type === 'moduleSela') {
    moduleOrUnitTitle = formatModuleFileTitle(moduleLabel, title)
  } else if (type === 'unitSela') {
    moduleOrUnitTitle = formatUnitFileTitle(
      moduleLabel,
      unitLabel,
      moduleTitle,
      title,
    )
  } else {
    console.error('Type is not moduleSela or unitSela')
    return null
  }

  const filename = `${schoolName} - Second Step SEL for Adults Report (${role}) - ${moduleOrUnitTitle} as of ${formattedDate}.csv`.trim()
  return filename
}

export const findDetailsById = (userDetails, contentfulId) => {
  const array = userDetails?.user_children
    ? userDetails.user_children
    : userDetails
  const isArray = Array.isArray(array)

  if (isArray) {
    const matchingDetails = array
      ?.map(details => {
        const hasChildren = details?.user_children
        const idMatches = details?.contentful_id === contentfulId

        if (idMatches) {
          return details
        } else if (hasChildren) {
          return findDetailsById(details?.user_children, contentfulId)
        }
      })
      .filter(data => data)
      .flat()[0]

    return matchingDetails
  }
}

const selectReportsError = state => state.reportsManager.error
const selectK8Reports = state => state.reportsManager.k8Reports // TODO: normalize for gradeDetails?
const selectSelectedSite = state => state.reportsManager.selectedSite

// SchoolSummary
const selectIsFetchingSchoolReport = state => {
  return state.reportsManager.isFetchingSchool
}

export const selectSchoolAllGrades = createSelector(
  [selectIsFetchingSchoolReport, selectSelectedSite, selectK8Reports],
  (isFetching, selectedSiteId, reports) => {
    let report = isFetching ? {} : null
    if (selectedSiteId) {
      const schoolData = reports[selectedSiteId]
      if (schoolData?.allGrades) {
        const { allGrades } = schoolData
        const sortedGrades = sortGrades([...allGrades])
        report = {
          allGrades: sortedGrades,
        }
      }
    }
    return report
  },
)

// args: state
export const selectSchoolSummaryReport = createSelector(
  [
    selectIsFetchingSchoolReport,
    selectSelectedSite,
    selectK8Reports,
    selectReportsError,
  ],
  (isFetching, selectedSiteId, reports, error) => {
    let report = isFetching || error ? {} : null
    if (selectedSiteId) {
      const schoolData = reports[selectedSiteId]
      const schoolDataExists = !!(schoolData && schoolData?.siteName)
      if (schoolDataExists) {
        const {
          administratorsCount,
          allGrades,
          hasClassesWithCompletedLessonLastPeriod,
          numClassesWithCompletedLessonLastPeriod,
          period,
          lastUpdatedAt,
          pendingInvitations,
          siteId,
          siteName,
          teachersCount,
          totalClassesCount,
        } = schoolData
        const splitGrades = sortGrades([...allGrades]).reduce(
          (gradesAccumulator, gradeObj) => {
            if (gradeObj.classesCount > 0) {
              gradesAccumulator.hasClasses.push(gradeObj)
            } else {
              gradesAccumulator.noClasses.push(gradeObj)
            }
            return gradesAccumulator
          },
          { hasClasses: [], noClasses: [] }, // gradesAccumulator init
        )
        report = {
          administratorsCount,
          gradesWithClasses: splitGrades.hasClasses,
          gradesWithNoClasses: splitGrades.noClasses,
          hasClassesWithCompletedLessonLastPeriod,
          numClassesWithCompletedLessonLastPeriod,
          period,
          lastUpdatedAt,
          pendingInvitations,
          siteId,
          siteName,
          teachersCount,
          totalClassesCount,
        }
      }
    }
    return report
  },
)

/**
 * @deprecated To be removed via LEARN-16518
 */
export const selectRealSiteId = state => {
  const activeDigitalLicenses = state.licenseManager.activeDigitalLicenses || []
  // this says siteId but it's a licenseId
  const selectedlicenseId = selectSelectedSite(state)
  const activeDigitalLicense = activeDigitalLicenses.find(
    license => license.licenseId === selectedlicenseId,
  )

  // license/me returns a real siteId to link to LUM
  return activeDigitalLicense?.siteId
}

// GradeDetails
const selectIsFetchingGradeReport = state => {
  return state.reportsManager.isFetchingGrade
}
const selectSelectedGrade = state => state.reportsManager.selectedGrade

// args: state
export const selectGradeReport = createSelector(
  [
    selectIsFetchingGradeReport,
    selectSelectedSite,
    selectSelectedGrade,
    selectK8Reports,
    selectReportsError,
  ],
  (isFetching, selectedSiteId, selectedGradeName, reports, error) => {
    let report = isFetching || error ? {} : null
    if (selectedSiteId && selectedGradeName) {
      const { period, lastUpdatedAt, gradeDetails } =
        reports[selectedSiteId] || {}
      const selectedGradeDetails = gradeDetails?.[selectedGradeName]
      if (selectedGradeDetails) {
        report = { period, lastUpdatedAt, ...selectedGradeDetails }
      }
    }
    return report
  },
)

// ClassDetails
const selectIsFetchingClassReport = state => {
  return state.reportsManager.isFetchingClass
}
const selectClassDetails = state => {
  return state.reportsManager.k8Classes
}
// args: state, classInstance
export const selectClassDetailsReport = createSelector(
  [
    selectIsFetchingClassReport,
    selectClassDetails,
    (state, classInstance) => classInstance,
    selectReportsError,
  ],
  (isFetching, classDetails, classInstance, error) => {
    let report = isFetching || error ? {} : null
    if (classDetails && classInstance) {
      report = classDetails[classInstance] || report
    }
    // TODO: this is where the data is coming from, hopefully pass-through, but if not, munge here
    return report
  },
)

export const selectAdminSites = createSelector(
  [
    state => state.licenseManager.activeDigitalLicenses,
    state => state.reportsManager.admin.k8SitesList,
    state => state.reportsManager.admin.selaSitesList,
    state => state.reportsManager.selectedProgram,
  ],
  (activeLicenses, k8Sites, selaSites, selectedProgram) => {
    if (!activeLicenses || !selectedProgram) return null
    if (!k8Sites && !selaSites) return null

    const programSiteMap = {
      K8: k8Sites,
      SELA: selaSites,
    }
    const selectedProgramSites = programSiteMap[selectedProgram.key] || []
    const activeLicensesMap = new Map()
    for (const license of activeLicenses) {
      activeLicensesMap.set(license.licenseId, license)
    }
    // TODO: this is a stop-gap to add siteId to licenses for BTS - this will have to be updated during work to surface expired sites in LEARN-15536 (and add siteId to views in koalafied work)
    const activeAdminSites = selectedProgramSites.map(site => {
      const { licenseId } = site
      const { siteId } = activeLicensesMap.get(licenseId) || {}
      return {
        ...site,
        siteId,
      }
    })
    return activeAdminSites
  },
)

export const selectReportingAdminSites = createSelector(
  [
    state => state.reportsManager.admin.k8SitesList,
    state => state.reportsManager.admin.selaSitesList,
    state => state.reportsManager.selectedProgram,
  ],
  (k8Sites, selaSites, selectedProgram) => {
    if (!selectedProgram) return null
    if (!k8Sites && !selaSites) return null

    const programSiteMap = {
      K8: k8Sites,
      SELA: selaSites,
    }
    const selectedProgramSites = programSiteMap[selectedProgram.key] || []

    return selectedProgramSites
  },
)

/**
 * NOTE: for internal use
 * Transforms raw data from the store into a format that can be used by the AdminMultiSiteView and AdminSingleSiteView components
 * @return {{
 *  sites: Array<{
 *    classesCreated: number,
 *    classesNotStarted: number,
 *    grades: {
 *      'All Core Lessons' | 'All Lessons' | 'Unit 1' | 'Unit 2' | 'Unit 3' | 'Unit 4': Array<{
 *        classesNotStarted: number,
 *        lessonsCompleted: number,
 *        lessonsCompletedPercent: number,
 *        name: string,
 *        numOfClasses: number,
 *        totalLessons: number,
 *      }>
 *    },
 *    name: string,
 *    siteId: string,
 *    summary: {
 *      core: {
 *        completedLessons: number,
 *        completionPercentage: number,
 *        totalLessons: number,
 *      },
 *      unit5: {
 *        completedLessons: number,
 *        completionPercentage: number,
 *        totalLessons: number,
 *      },
 *      completedLessons: number,
 *      completionPercentage: number,
 *      totalLessons: number,
 *    },
 *    units: Array<{
 *      completedLessons: number,
 *      completionPercentage: number,
 *      totalLessons: number,
 *    }>,
 *  }>,
 *  summary: {
 *    classesCreated: number,
 *    classesNotStarted: number,
 *    classesStarted: number,
 *    etlLastUpdatedAt: string,
 *    lessonsCompleted: number,
 *    lessonsTotal: number,
 *    sitesTotal: number,
 *  }
 * }} The transformed data in the format expected by the components
 */
export const selectAdminLessonCompletionData = createSelector(
  [
    state => state.reportsManager.admin.lastUpdatedAt,
    state => state.reportsManager.admin.schools,
    state => state.reportsManager.admin.unitSummary,
    state => state.reportsManager.isFetchingAdminLessonCompletionData,
    state => state.reportsManager.error,
  ],
  (lastUpdatedAt, schools, unitSummary, isFetching, error) => {
    if (isFetching || error) {
      return {}
    }
    if (!lastUpdatedAt || !schools || !unitSummary) {
      return null
    }

    const allSitesSummary = {
      classesCreated: 0,
      classesNotStarted: 0,
      classesStarted: 0,
      etlLastUpdatedAt: lastUpdatedAt.last_updated_at,
      lessonsCompleted: 0,
      lessonsTotal: 0,
      sitesTotal: 0,
    }

    const schoolIdToUnitNumToUnitSummaryByGrade = unitSummary.reduce(
      (acc, us) => {
        const { site_id, grade, unit_number } = us
        if (!acc[site_id]) {
          acc[site_id] = {
            [unit_number]: {
              [grade]: us,
            },
          }
        } else if (!acc[site_id][unit_number]) {
          acc[site_id][unit_number] = {
            [grade]: us,
          }
        } else {
          acc[site_id][unit_number][grade] = us
        }
        return acc
      },
      {},
    )

    const transformToGradeList = siteId => {
      const unitNumToUnitSummaryByGrade =
        schoolIdToUnitNumToUnitSummaryByGrade?.[siteId] || {}
      const gradeList = Object.entries(unitNumToUnitSummaryByGrade)?.reduce(
        (acc, [unitNum, unitSummaryByGrade]) => {
          const unitKey = unitNum === 'All' ? 'All Lessons' : `Unit ${unitNum}`
          const unitAcc = acc[unitKey]
          if (!unitAcc) {
            console.error('unitAcc not found for', unitKey)
            return acc
          }
          for (const [grade, us] of Object.entries(unitSummaryByGrade)) {
            if (grade === 'All') continue

            const gradeKey = REPORTING_UNIT_SUMMARY_TO_LEARN_GRADES[grade]
            const gradeIdx = SORTED_GRADE_IDX[gradeKey]
            const gradeAcc = unitAcc[gradeIdx]
            gradeAcc.numOfClasses = us.class_count
            gradeAcc.classesNotStarted = us.class_count - us.classes_started
            gradeAcc.lessonsCompleted = us.lessons_marked_done
            gradeAcc.lessonsCompletedPercent = us.completion_percentage
            gradeAcc.totalLessons = us.total_lessons
          }

          return acc
        },
        cloneDeep(defaultGrades),
      )
      return gradeList
    }

    const transformToLessonCompletionByUnit = siteId => {
      const unitNumToUnitSummaryByGrade =
        schoolIdToUnitNumToUnitSummaryByGrade?.[siteId] || {}

      const completionPercentageByUnitArray = Array.from(
        { length: MAX_UNITS_PER_CLASS },
        () => ({
          completedLessons: 0,
          totalLessons: 0,
          completionPercentage: 0,
        }),
      )

      for (const [unitNumber, unitSummaryByGrade] of Object.entries(
        unitNumToUnitSummaryByGrade,
      )) {
        if (unitNumber === 'All') continue
        for (const [grade, us] of Object.entries(unitSummaryByGrade)) {
          if (grade !== 'All') continue
          const { unit_number } = us
          const unitIdx = unit_number - 1
          const unitData = completionPercentageByUnitArray[unitIdx]
          unitData.completedLessons = us.lessons_marked_done
          unitData.totalLessons = us.total_lessons
          unitData.completionPercentage = us.completion_percentage
        }
      }

      return completionPercentageByUnitArray
    }

    const addAllCoreLessonMetricsToGrades = grades => {
      // copy all lessons to all core lessons before removing unit 5 metrics from core lessons
      grades['All Core Lessons'] = JSON.parse(
        JSON.stringify(grades['All Lessons']),
      )
      const coreLessons = grades['All Core Lessons']
      const unit5Lessons = grades['Unit 5']

      // subtract unit 5 metrics from core lessons
      for (let i = 0; i < coreLessons.length; i++) {
        const coreGrade = coreLessons[i]
        const unit5Grade = unit5Lessons[i]
        coreGrade.lessonsCompleted -= unit5Grade.lessonsCompleted
        coreGrade.totalLessons -= unit5Grade.totalLessons
        coreGrade.lessonsCompletedPercent =
          Math.round(
            (coreGrade.lessonsCompleted / coreGrade.totalLessons) * 100,
          ) / 100 || 0
      }
      return grades
    }

    const buildSiteSummary = (school, grades, units) => {
      const {
        lessons_marked_done,
        total_lessons,
        schoolwide_lesson_completion_percentage,
      } = school

      // units sorted 1-5 zero-indexed
      const {
        completedLessons: unit5CompletedLessons,
        totalLessons: unit5TotalLessons,
      } = units[4]
      // grades sorted k-8 zero-indexed
      const unit5Classes =
        grades?.['Unit 5']?.[6]?.numOfClasses +
        grades?.['Unit 5']?.[7]?.numOfClasses +
        grades?.['Unit 5']?.[8]?.numOfClasses

      return {
        core: {
          completedLessons: lessons_marked_done - unit5CompletedLessons,
          totalLessons: total_lessons - unit5TotalLessons,
          completionPercentage:
            // floor (vs round) for parity with reporting
            Math.floor(
              ((lessons_marked_done - unit5CompletedLessons) /
                (total_lessons - unit5TotalLessons)) *
                100,
            ) / 100 || 0,
        },
        unit5: {
          classesCreated: unit5Classes,
          completedLessons: unit5CompletedLessons,
          totalLessons: unit5TotalLessons,
          completionPercentage:
            // floor (vs round) for parity with reporting
            Math.floor((unit5CompletedLessons / unit5TotalLessons) * 100) /
              100 || 0,
        },
        completedLessons: lessons_marked_done,
        totalLessons: total_lessons,
        completionPercentage: schoolwide_lesson_completion_percentage,
      }
    }

    const lessonCompletionBySchool = schools.map(school => {
      const {
        site_id,
        school_name,
        classes_created,
        classes_started,
        lessons_marked_done,
        total_lessons,
      } = school

      const grades = transformToGradeList(site_id)
      const units = transformToLessonCompletionByUnit(site_id)

      // save a loop by adding to allSitesSummary here
      allSitesSummary.classesCreated += classes_created
      allSitesSummary.classesNotStarted += classes_created - classes_started
      allSitesSummary.classesStarted += classes_started
      allSitesSummary.lessonsCompleted += lessons_marked_done
      allSitesSummary.lessonsTotal += total_lessons
      allSitesSummary.sitesTotal++
      return {
        siteId: site_id,
        name: school_name,
        classesCreated: classes_created,
        classesNotStarted: classes_created - classes_started,
        summary: buildSiteSummary(school, grades, units),
        units,
        grades: addAllCoreLessonMetricsToGrades(grades),
      }
    })

    return {
      sites: lessonCompletionBySchool,
      summary: allSitesSummary,
    }
  },
)

/**
 * NOTE: for internal use
 * Transforms raw data from the store into a format that can be used by the AdminMultiSiteView and AdminSingleSiteView components
 * @return {{
 *  summary: {
 *    core: {
 *      lessonsCompleted: number,
 *      lessonsTotal: number,
 *    },
 *    unit5: {
 *      lessonsCompleted: number,
 *      lessonsTotal: number,
 *    },
 *    classesCreated: number,
 *    classesNotStarted: number,
 *    classesStarted: number,
 *    etlLastUpdatedAt: string,
 *    lessonsCompleted: number,
 *    lessonsTotal: number,
 *    sitesTotal: number,
 *  }
 * }} The transformed data in the format expected by the components
 */
export const selectAdminLessonCompletionSummaryData = createSelector(
  [selectAdminLessonCompletionData],
  lessonCompletionData => {
    if (!lessonCompletionData?.summary) return null

    const unit5Summary = {
      lessonsCompleted: 0,
      lessonsTotal: 0,
    }

    const { summary, sites } = lessonCompletionData
    sites.forEach(({ units }) => {
      const unit5 = units[4] // units array is zero-indexed and sorted, meaning units[4] corresponds to the 5th unit
      unit5Summary.lessonsCompleted += unit5.completedLessons
      unit5Summary.lessonsTotal += unit5.totalLessons
    })
    summary.core = {
      lessonsCompleted:
        summary.lessonsCompleted - unit5Summary.lessonsCompleted,
      lessonsTotal: summary.lessonsTotal - unit5Summary.lessonsTotal,
    }
    summary.unit5 = unit5Summary

    return summary
  },
)

export const selectSortedAdminLessonCompletionData = createSelector(
  [
    selectAdminLessonCompletionData,
    state => state.reportsManager.admin.monitorPagination.currentPage,
    state => state.reportsManager.admin.monitorPagination.sitesPerPage,
    state => state.reportsManager.admin.monitorPagination.sortKey,
    state => state.reportsManager.admin.monitorPagination.orderBy,
  ],
  (lessonCompletionData, currentPage, sitesPerPage, sortKey, orderBy) => {
    if (!lessonCompletionData?.sites) return null

    const { sites } = lessonCompletionData

    let sortedSites = []
    const order = orderBy === 'asc' ? 1 : -1
    switch (sortKey) {
      case 'name':
        sortedSites = [...sites].sort((a, b) =>
          orderBy === 'asc'
            ? a.name?.localeCompare(b.name)
            : b.name?.localeCompare(a.name),
        )
        break
      case 'classesStarted':
        sortedSites = [...sites].sort((a, b) => {
          if (a.classesCreated === 0 && orderBy === 'asc') return -1
          if (b.classesCreated === 0 && orderBy === 'asc') return order

          if (a.classesCreated === 0 && orderBy === 'desc') return 1
          if (b.classesCreated === 0 && orderBy === 'desc') return order

          return (
            order *
            (a.classesCreated -
              a.classesNotStarted -
              (b.classesCreated - b.classesNotStarted))
          )
        })
        break
      case 'completedLessons':
        sortedSites = [...sites].sort((a, b) => {
          if (a.summary.totalLessons === 0 && orderBy === 'asc') return -1
          if (b.summary.totalLessons === 0 && orderBy === 'asc') return order

          if (a.summary.totalLessons === 0 && orderBy === 'desc') return 1
          if (b.summary.totalLessons === 0 && orderBy === 'desc') return order

          return (
            order *
            (a.summary.completionPercentage - b.summary.completionPercentage)
          )
        })
        break
      case 'unit1':
        sortedSites = [...sites].sort((a, b) => {
          if (a.units[0].totalLessons === 0 && orderBy === 'asc') return -1
          if (b.units[0].totalLessons === 0 && orderBy === 'asc') return order

          if (a.units[0].totalLessons === 0 && orderBy === 'desc') return 1
          if (b.units[0].totalLessons === 0 && orderBy === 'desc') return order

          return (
            order *
            (a.units[0].completionPercentage - b.units[0].completionPercentage)
          )
        })
        break
      case 'unit2':
        sortedSites = [...sites].sort((a, b) => {
          if (a.units[1].totalLessons === 0 && orderBy === 'asc') return -1
          if (b.units[1].totalLessons === 0 && orderBy === 'asc') return order

          if (a.units[1].totalLessons === 0 && orderBy === 'desc') return 1
          if (b.units[1].totalLessons === 0 && orderBy === 'desc') return order

          return (
            order *
            (a.units[1].completionPercentage - b.units[1].completionPercentage)
          )
        })
        break
      case 'unit3':
        sortedSites = [...sites].sort((a, b) => {
          if (a.units[2].totalLessons === 0 && orderBy === 'asc') return -1
          if (b.units[2].totalLessons === 0 && orderBy === 'asc') return order

          if (a.units[2].totalLessons === 0 && orderBy === 'desc') return 1
          if (b.units[2].totalLessons === 0 && orderBy === 'desc') return order

          return (
            order *
            (a.units[2].completionPercentage - b.units[2].completionPercentage)
          )
        })
        break
      case 'unit4':
        sortedSites = [...sites].sort((a, b) => {
          if (a.units[3].totalLessons === 0 && orderBy === 'asc') return -1
          if (b.units[3].totalLessons === 0 && orderBy === 'asc') return order

          if (a.units[3].totalLessons === 0 && orderBy === 'desc') return 1
          if (b.units[3].totalLessons === 0 && orderBy === 'desc') return order

          return (
            order *
            (a.units[3].completionPercentage - b.units[3].completionPercentage)
          )
        })
        break
      default:
        sortedSites = [...sites]
    }

    const startIndex = (currentPage - 1) * sitesPerPage
    const endIndex = startIndex + sitesPerPage
    const paginatedSites = sortedSites.slice(startIndex, endIndex)

    return paginatedSites
  },
)

/**
 * Selector for getting high school launch snapshot sites.
 *
 * This selector fetches several pieces of state.
 * It then processes this data to create an array of launch snapshot sites.
 *
 * If the data is still being fetched or if there was an error, this selector
 * returns an empty object or null, respectively.
 *
 * @function
 * @name selectAdminHsLaunchSnapshotSites
 * @param {{ site_id: string, school_name: string }[]} schools - An array of school objects.
 * @param {{ site_id: string, user_status: string, role: string }[]} users - An array of user objects.
 * @param {{ id: string, sitePreferences: { highSchool: { implementationLevel: number } } }[]} ssapiActiveAdminHsSites - An array of high school site objects with preferences.
 * @param {boolean} isFetchingReporting - A boolean indicating if the reporting data is being fetched.
 * @param {any} errorReporting - An object representing any errors that occurred while fetching the reporting data.
 * @param {boolean} isFetchingUserContext - A boolean indicating if the user context data is being fetched.
 * @param {any} errorUserContext - An object representing any errors that occurred while fetching the user context data.
 * @param {{ siteId: string, preferences: { areUsersAdded: boolean } }[]} userContextSites - An array of high school site objects with preferences. Needed to get areUsersAdded until it is surfaced in sites/me response.
 * @return {Array<{
 *   siteName: string,
 *   siteId: string,
 *   preferences: { areUsersAdded: boolean, implementationLevel: number, isSetup: boolean },
 *   adminsPendingCount: number,
 *   teachersPendingCount: number,
 *   totalUsersCount: number,
 * }> | {} | null} The transformed data in the format expected by the components
 */
export const selectAdminHsLaunchSnapshotSites = createSelector(
  [
    state => state.reportsManager.admin.hs.schools,
    state => state.reportsManager.admin.hs.users,
    licenseSelectors.selectActiveAdminHsSites,
    state => state.reportsManager.admin.hs.isFetching,
    state => state.reportsManager.error,
    state => state.userContextManager.isFetching,
    state => state.userContextManager.error,
    userContextSelectors.selectHighSchoolSiteIdAndPreferences,
    state => state.reportsManager.admin.hs.schoolTraining,
  ],
  (
    schools,
    users,
    ssapiActiveAdminHsSites, // this surfaces non-calculated prefs
    isFetchingReporting,
    errorReporting,
    isFetchingUserContext,
    errorUserContext,
    userContextSites, // this surfaces calculated prefs (isSetup, isPacingComplete, isImplementationPlanComplete, areUsersAdded)
    schoolTraining,
  ) => {
    const isNotSettled =
      isFetchingReporting ||
      errorReporting ||
      isFetchingUserContext ||
      errorUserContext ||
      false
    const isNotHydrated =
      !schools || !users || !ssapiActiveAdminHsSites || !userContextSites

    // component: prevent refetching with `if (!adminHsLaunchSnapshot) fetch()`
    // component: prevent rendering with `if (Array.isArray(adminHsLaunchSnapshot)) render()`
    if (isNotSettled) {
      return {}
    }
    if (isNotHydrated) {
      return null
    }

    const usersBySiteId = users.reduce((acc, user) => {
      const { site_id } = user
      if (!acc[site_id]) {
        acc[site_id] = [user]
      } else {
        acc[user.site_id].push(user)
      }
      return acc
    }, {})

    const ssapiSitesBySiteId = ssapiActiveAdminHsSites.reduce(
      (acc, ssapiSite) => {
        acc[ssapiSite.id] = ssapiSite
        return acc
      },
      {},
    )

    const calculatedPrefsBySiteId = userContextSites.reduce((acc, hsSite) => {
      acc[hsSite.siteId] = hsSite
      return acc
    }, {})
    const launchSnapshotSites = schools
      .filter(school => {
        return !!ssapiSitesBySiteId?.[school.site_id]
      })
      .map(school => {
        const isHsMonitorFlagOn = isFlagOn(['feature_learn-18531-hs-monitor'])
        const { site_id: siteId, school_name: siteName } = school
        const users = usersBySiteId[siteId] || []
        const {
          sitePreferences: { highSchool: preferences },
        } = ssapiSitesBySiteId[siteId] || {}
        const hsPacing = ssapiSitesBySiteId[siteId]?.pacing?.highSchool || []
        let currentPacingName = 'School setup required'
        let currentPacingEndDate = new Date(0).toISOString()
        let currentPacingEndDateFormatted

        if (isHsMonitorFlagOn && preferences?.isSetup && hsPacing?.length > 1) {
          const sortedByDescEndStartHsPacing = hsPacing
            .filter(collection => collection && collection.startDate)
            .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
            .map(collection => {
              const { startDate, endDate } = collection
              const zeroedEndDate = new Date(endDate).setHours(0, 0, 0, 0)
              const zeroedStartDate = new Date(startDate).setHours(0, 0, 0, 0)
              return {
                endDate: new Date(zeroedEndDate).toISOString(),
                startDate: new Date(zeroedStartDate).toISOString(),
                nodeFriendlyName: collection.nodeFriendlyName,
              }
            })

          const todaysDate = new Date(
            new Date().setHours(0, 0, 0, 0),
          ).toISOString()

          if (todaysDate < sortedByDescEndStartHsPacing[0].startDate) {
            currentPacingName = 'Staff Kick-Off'
            const kickOffEndDate = new Date(
              sortedByDescEndStartHsPacing[0].startDate,
            )
            kickOffEndDate.setDate(kickOffEndDate.getDate() - 1)
            const formattedKickOffEndDate = kickOffEndDate.toLocaleDateString(
              'en-US',
              {
                month: 'short',
                day: '2-digit',
              },
            )
            currentPacingEndDate = kickOffEndDate.toISOString()
            currentPacingEndDateFormatted = formattedKickOffEndDate
          } else if (
            todaysDate >= sortedByDescEndStartHsPacing[0].startDate &&
            todaysDate < sortedByDescEndStartHsPacing[1].startDate
          ) {
            currentPacingName =
              pathwayNameMap?.[
                sortedByDescEndStartHsPacing[0].nodeFriendlyName
              ] || ''
            const trainingEndDate = new Date(
              sortedByDescEndStartHsPacing[1].startDate,
            )
            trainingEndDate.setDate(trainingEndDate.getDate() - 1)
            const formattedTrainingEndDate = trainingEndDate.toLocaleDateString(
              'en-US',
              {
                month: 'short',
                day: '2-digit',
              },
            )
            currentPacingEndDate = trainingEndDate.toISOString()
            currentPacingEndDateFormatted = formattedTrainingEndDate
          } else {
            const lastCollection =
              sortedByDescEndStartHsPacing[
                sortedByDescEndStartHsPacing.length - 1
              ]
            const currentCollection =
              sortedByDescEndStartHsPacing.find(collection => {
                return todaysDate <= collection.endDate
              }) || lastCollection

            const nextPathwayCollection =
              sortedByDescEndStartHsPacing
                .slice(sortedByDescEndStartHsPacing.indexOf(currentCollection))
                .find(collection => {
                  if (
                    currentCollection.nodeFriendlyName === 'hs program training'
                  ) {
                    return collection.nodeFriendlyName === 'hs b&c collection 1'
                  } else {
                    return !collection.nodeFriendlyName.includes(
                      currentCollection.nodeFriendlyName.slice(0, -1),
                    )
                  }
                }) || currentCollection
            const currentPathway =
              pathwayNameMap?.[currentCollection.nodeFriendlyName] || ''
            currentPacingName = currentPathway
            const pathwayEndDate = new Date(nextPathwayCollection.startDate)

            if (currentCollection === lastCollection) {
              if (currentCollection.nodeFriendlyName.endsWith(3)) {
                pathwayEndDate.setDate(pathwayEndDate.getDate() + 14)
              }
              if (currentCollection.nodeFriendlyName.endsWith(2)) {
                pathwayEndDate.setDate(pathwayEndDate.getDate() + 21)
              }
            } else {
              pathwayEndDate.setDate(pathwayEndDate.getDate() - 1)
            }

            const formattedPathwayEndDate = pathwayEndDate.toLocaleDateString(
              'en-US',
              {
                month: 'short',
                day: '2-digit',
              },
            )
            currentPacingEndDate = pathwayEndDate.toISOString()
            currentPacingEndDateFormatted = formattedPathwayEndDate
          }
        }

        // destructure calculated prefs
        const {
          preferences: {
            areUsersAdded,
            isSetup,
            isImplementationPlanComplete,
            isPacingComplete,
          },
        } = calculatedPrefsBySiteId[siteId] || {}

        const calculatedPrefs = {
          areUsersAdded: preferences?.areUsersAdded || areUsersAdded,
          isSetup: preferences?.isSetup || isSetup,
          isImplementationPlanComplete:
            preferences?.isImplementationPlanComplete ||
            isImplementationPlanComplete,
          isPacingComplete: preferences?.isPacingComplete || isPacingComplete,
        }

        if (!preferences) {
          console.error(`No preferences found for ${siteId}`)
        }
        const userCounts = {
          adminsActiveCount: 0,
          adminsPendingCount: 0,
          teachersActiveCount: 0,
          teachersPendingCount: 0,
        }
        for (const user of users) {
          if (user.user_status === 'pending') {
            if (user.role === 'Admin') {
              userCounts.adminsPendingCount++
            } else if (user.role === 'Teacher') {
              userCounts.teachersPendingCount++
            }
          } else if (user.user_status === 'accepted') {
            if (user.role === 'Admin') {
              userCounts.adminsActiveCount++
            } else if (user.role === 'Teacher') {
              userCounts.teachersActiveCount++
            }
          }
        }

        const schoolTrainingData = schoolTraining.find(
          school => school.site_id === siteId,
        )

        return {
          ...userCounts,
          totalUsersCount: users?.length,
          siteName,
          siteId,
          currentPacingEndDate,
          currentPacingEndDateFormatted,
          currentPacingName,
          preferences: {
            ...preferences,
            ...calculatedPrefs,
          },
          schoolTraining: schoolTrainingData,
        }
      })

    return launchSnapshotSites
  },
)

export const selectAdminHsLaunchSnapshotSummary = createSelector(
  [selectAdminHsLaunchSnapshotSites],
  sites => {
    if (!Array.isArray(sites)) return {}

    let totalPendingCount = 0
    let schoolsRequiringSetup = 0
    let totalUsers = 0

    for (const site of sites) {
      if (!site.preferences.isLaunched) {
        schoolsRequiringSetup++
      }
      totalPendingCount += site.adminsPendingCount + site.teachersPendingCount
      totalUsers += site.totalUsersCount
    }

    return {
      totalSchools: sites.length,
      schoolsRequiringSetup,
      totalPendingCount,
      totalUsers,
    }
  },
)

export const selectSortedAdminHsLaunchSnapshotSites = createSelector(
  [
    selectAdminHsLaunchSnapshotSites,
    state => state.router.location.query.sortKey,
    state => state.router.location.query.sortOrder,
    state => state.router.location.query.pageNumber,
    state => state.reportsManager.admin.hs.launchPagination.sitesPerPage,
    state => state.reportsManager.admin.hs.schoolTraining,
  ],
  (sites, sortKey, sortOrder, currentPage, sitesPerPage) => {
    const isNotSettled =
      !Array.isArray(sites) ||
      !sortKey ||
      !sortOrder ||
      !currentPage ||
      !sitesPerPage ||
      false
    if (isNotSettled) return sites

    let sortedSites = []
    const order = sortOrder === 'asc' ? 1 : -1

    switch (sortKey) {
      case 'school':
        sortedSites = sites.sort((a, b) => {
          return order * a.siteName.localeCompare(b.siteName)
        })
        break
      case 'users':
        sortedSites = sites.sort((a, b) => {
          return order * (a.totalUsersCount - b.totalUsersCount)
        })
        break
      case 'pacing':
        sortedSites = sites.sort((a, b) => {
          const aDateInSeconds = new Date(a.currentPacingEndDate).getTime()
          const bDateInSeconds = new Date(b.currentPacingEndDate).getTime()
          if (sortOrder === 'asc') {
            return bDateInSeconds - aDateInSeconds
          } else {
            return aDateInSeconds - bDateInSeconds
          }
        })
        break
      case 'educatorPractices':
        sortedSites = sites.sort((a, b) => {
          if (sortOrder === 'asc') {
            return b.educatorPractices - a.educatorPractices
          } else {
            return a.educatorPractices - b.educatorPractices
          }
        })
        break
      case 'schoolwidePractices':
        sortedSites = sites.sort((a, b) => {
          if (sortOrder === 'asc') {
            return b.schoolwidePractices - a.schoolwidePractices
          } else {
            return a.schoolwidePractices - b.schoolwidePractices
          }
        })
        break
      case 'studentActivities':
        sortedSites = sites.sort((a, b) => {
          if (sortOrder === 'asc') {
            return b.studentActivities - a.studentActivities
          } else {
            return a.studentActivities - b.studentActivities
          }
        })
        break

      /*
      There is a specific sorting logic for the the training progress column
      Ascending order
        1. site needed to set up
        2. site ready for launch
        3. site that has been launched
      Descending order
        1. site that has been launched
        2. site needed to set up
        3. site ready for launch
      Site states
        - School setup required --> (not set up AND not launched)
        - Ready to launch --> (set up BUT not launched)
        - set up AND launched

      The logic written below is to account for those 3 states and sort based on sorting order. 
      If two sites that are being compared have both been launched, we compare the training program completion percentage value
      */

      case 'training':
        sortedSites = sites.sort((a, b) => {
          if (sortOrder === 'asc') {
            const ascendingOrder = {
              'false-false': 0,
              'true-false': 1,
              'true-true': 2,
            }
            const aKey = `${a.preferences.isSetup}-${a.preferences.isLaunched}`
            const bKey = `${b.preferences.isSetup}-${b.preferences.isLaunched}`

            if (aKey === 'true-true' && bKey === 'true-true') {
              return (
                order *
                (a.schoolTraining.training_program_completion_percent -
                  b.schoolTraining.training_program_completion_percent)
              )
            }

            return ascendingOrder[aKey] - ascendingOrder[bKey]
          }

          if (sortOrder === 'desc') {
            const ascendingOrder = {
              'true-true': 0,
              'false-false': 1,
              'true-false': 2,
            }
            const aKey = `${a.preferences.isSetup}-${a.preferences.isLaunched}`
            const bKey = `${b.preferences.isSetup}-${b.preferences.isLaunched}`

            if (aKey === 'true-true' && bKey === 'true-true') {
              return (
                order *
                (a.schoolTraining.training_program_completion_percent -
                  b.schoolTraining.training_program_completion_percent)
              )
            }

            return ascendingOrder[aKey] - ascendingOrder[bKey]
          }

          return (
            order *
            (a.schoolTraining.training_program_completion_percent -
              b.schoolTraining.training_program_completion_percent)
          )
        })
        break
      default:
        sortedSites = [...sites]
    }

    const startIndex = (currentPage - 1) * sitesPerPage
    const endIndex = startIndex + sitesPerPage
    const paginatedSites = sortedSites.slice(startIndex, endIndex)

    return paginatedSites
  },
)

export default {
  selectAdminLessonCompletionData,
  createCsvData,
  selectAdminLessonCompletionSummaryData,
  selectAdminSites,
  selectReportingAdminSites,
  selectSchoolAllGrades,
  selectSchoolSummaryReport,
  selectRealSiteId,
  selectGradeReport,
  selectClassDetailsReport,
  selectAdminHsLaunchSnapshotSummary,
  selectAdminHsLaunchSnapshotSites,
  selectSortedAdminHsLaunchSnapshotSites,
  selectSortedAdminLessonCompletionData,
}
