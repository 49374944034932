import React from 'react'
import { PropTypes } from 'prop-types'
import { RouteContentProductAppMap } from 'utils/productMaps'
import CourseComponent from 'components/CourseComponent'
import UnitComponent from 'components/UnitComponent'
import { UnitType } from 'components/UnitComponent/types'
import {
  CourseManagerGetter,
  CourseManagerType,
} from 'layers/content/v2/Hocs/CourseManagerGetter'
import withApiError from 'layers/errorHandling/apiError'
import {
  RouteContentGetter,
  RouteContentType,
} from 'layers/content/v2/Hocs/RouteContentGetter'
import { findLmsUnit } from './utils'

function Container(props) {
  const {
    routeContent,
    selectedCourse,
    ourClass,
    match,
    productName,
    handleUnitSelect,
    unitId,
    getData,
    courseManager,
  } = props

  const { content, loading } = routeContent || {}
  const { courseTrees } = courseManager

  if (loading) {
    return
  }

  const { unit } = content
  const lmsUnit = findLmsUnit({ courseTrees, unitId, lmsClass: ourClass })
  const { lessonsAmounts, scopeAndSequenceData } = getData(unit, ourClass)

  return (
    <>
      <CourseComponent
        displayTitle={selectedCourse.title}
        entryId={selectedCourse.entryId}
        instance={ourClass.instance}
        isActive
        isEditable
        lessonsAmounts={lessonsAmounts}
        match={match}
        scopeAndSequenceLinks={scopeAndSequenceData}
        title={ourClass.title}
      >
        <UnitComponent
          entryId={selectedCourse.entryId}
          lmsUnit={lmsUnit}
          match={match}
          onSelectUnit={handleUnitSelect}
          productName={productName}
          unit={unit}
          unitId={unitId}
        />
      </CourseComponent>
    </>
  )
}

Container.propTypes = {
  courseManager: CourseManagerType.isRequired,
  getData: PropTypes.func,
  handleUnitSelect: PropTypes.func,
  match: PropTypes.object,
  ourClass: PropTypes.object,
  productName: PropTypes.string,
  routeContent: RouteContentType(UnitType).isRequired,
  selectedCourse: PropTypes.object,
  unitId: PropTypes.string,
}

const TeachViewContainer = CourseManagerGetter(
  RouteContentGetter(withApiError(Container), {
    app: RouteContentProductAppMap.learn,
  }),
)
TeachViewContainer.displayName = 'TeachViewContainer'

export default TeachViewContainer
