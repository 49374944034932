import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import AdminHsMonitorSnapshot from 'components/AdminHsMonitorSnapshot'
import AdminHSSetup from 'components/AdminHSSetup'
import HighSchoolLaunchSnapshot from 'components/admin/LaunchSnapshot/HighSchool'
import {
  Bullet,
  CollapsibleListItem,
  ColumnCopy,
  ColumnWrapper,
  EducatorPracticesColumn,
  NameWrapper,
  PacingColumn,
  PanelWrapper,
  PendingCount,
  SchoolwidePracticesColumn,
  StaffTrainingCompletionColumn,
  StudentActivitiesColumn,
  StyledProgressBarIndicator,
} from './component.styles'
import { LAUNCH_TEXT, PENDING, SCHOOL_SETUP_TEXT } from './constants'

const LaunchListItem = ({
  handleListItemClick,
  index,
  isMultiSite,
  isOpen,
  selectedProgramKey,
  site,
  toggleView,
}) => {
  const {
    currentPacingEndDateFormatted,
    currentPacingName,
    adminsPendingCount,
    educatorPractices = 0,
    preferences,
    schoolwidePractices = 0,
    siteId,
    siteName,
    studentActivities = 0,
    teachersPendingCount,
    totalUsersCount,
    schoolTraining,
  } = site || {}
  const { isLaunched, isSetup } = preferences || {}
  const { training_program_completion_percent = 0 } = schoolTraining || {}
  const percentageComplete = Math.floor(
    training_program_completion_percent * 100.0,
  )
  const keyText = `${siteName}-${siteId}`
  const totalPendingCount = adminsPendingCount + teachersPendingCount

  const ListItemTitle = () => (
    <PanelWrapper>
      <NameWrapper>
        {!isLaunched && <Bullet />}
        {siteName}
      </NameWrapper>
      {toggleView === TOGGLE_VIEW.LAUNCH && (
        <Fragment key="launch-list-item-launch">
          <ColumnWrapper>
            <ColumnCopy>{totalUsersCount}</ColumnCopy>
            {adminsPendingCount + teachersPendingCount > 0 && (
              <PendingCount>
                <Bullet />
                {adminsPendingCount + teachersPendingCount} {PENDING}
              </PendingCount>
            )}
          </ColumnWrapper>
          <ColumnWrapper>
            {isLaunched && (
              <StaffTrainingCompletionColumn>
                <StyledProgressBarIndicator
                  keyText={keyText}
                  percentComplete={percentageComplete}
                  shouldRenderIndicator={isLaunched}
                />
              </StaffTrainingCompletionColumn>
            )}
            {!isLaunched && !isSetup && (
              <StaffTrainingCompletionColumn>
                {SCHOOL_SETUP_TEXT}
              </StaffTrainingCompletionColumn>
            )}
            {!isLaunched && isSetup && (
              <StaffTrainingCompletionColumn>
                {LAUNCH_TEXT}
              </StaffTrainingCompletionColumn>
            )}
          </ColumnWrapper>
        </Fragment>
      )}
      {toggleView === TOGGLE_VIEW.MONITOR && (
        <Fragment key="launch-list-item-monitor">
          <ColumnWrapper>
            <PacingColumn>
              <p>
                {!isSetup && <Bullet color="yellow" />} {currentPacingName}
              </p>
              {isSetup && <p>{`Ends on ${currentPacingEndDateFormatted}`}</p>}
            </PacingColumn>
          </ColumnWrapper>
          <ColumnWrapper>
            <SchoolwidePracticesColumn>{`${schoolwidePractices}%`}</SchoolwidePracticesColumn>
          </ColumnWrapper>
          <ColumnWrapper>
            <EducatorPracticesColumn>{`${educatorPractices}%`}</EducatorPracticesColumn>
          </ColumnWrapper>
          <ColumnWrapper>
            <StudentActivitiesColumn>
              {studentActivities}
            </StudentActivitiesColumn>
          </ColumnWrapper>
        </Fragment>
      )}
    </PanelWrapper>
  )

  return (
    <CollapsibleListItem
      ariaLabel={`Site Accordion for ${siteName}`}
      followActiveProp={true}
      isActive={isOpen}
      key={`collapsible-${siteId}-${index}`}
      onClick={handleListItemClick}
      title={ListItemTitle()}
      totalPendingCount={totalPendingCount}
    >
      {isLaunched && (
        <Fragment key="hs-multi-site-is-launched-view">
          {toggleView === TOGGLE_VIEW.LAUNCH && (
            <HighSchoolLaunchSnapshot
              programKey={selectedProgramKey}
              site={site}
            />
          )}
          {toggleView === TOGGLE_VIEW.MONITOR && (
            <AdminHsMonitorSnapshot isMultiSite={isMultiSite} site={site} />
          )}
        </Fragment>
      )}
      {!isLaunched && (
        <AdminHSSetup
          hideSetupHeader={true}
          isMultiSite={isMultiSite}
          preferences={preferences}
          programKey={selectedProgramKey}
          showHairline={false}
          siteId={siteId}
          siteName={siteName}
        />
      )}
    </CollapsibleListItem>
  )
}

LaunchListItem.propTypes = {
  handleListItemClick: PropTypes.func,
  index: PropTypes.number,
  isMultiSite: PropTypes.bool,
  isOpen: PropTypes.bool,
  selectedProgramKey: PropTypes.string,
  site: PropTypes.shape({
    adminsActiveCount: PropTypes.number,
    adminsPendingCount: PropTypes.number,
    currentPacingEndDateFormatted: PropTypes.string,
    currentPacingName: PropTypes.string,
    preferences: PropTypes.shape({
      isLaunched: PropTypes.bool,
      isSetup: PropTypes.bool,
    }),
    siteId: PropTypes.number,
    siteName: PropTypes.string,
    teachersActiveCount: PropTypes.number,
    teachersPendingCount: PropTypes.number,
    totalUsersCount: PropTypes.number,
  }),
  toggleView: PropTypes.string,
}

export default LaunchListItem
