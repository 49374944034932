import { SELA_ACTIVE_STATUS, SELA_PROGRESS_STATUS } from './enums'
import { NO_DATA_PLACEHOLDER } from './constants'

export const createChoiceCurriculumHeaders = (detail, createdHeaders) => {
  const meetings = detail.user_children

  meetings.forEach(meeting => {
    const newHeaderKey = `${meeting.display_title} - ${meeting.sub_content_type}`

    const newHeaderLabel =
      meeting.sub_content_type === 'leader-group'
        ? 'Attended Leader Team Meeting'
        : 'Attended Small-Group Meeting'

    const headerExists = createdHeaders.some(header => {
      return header.key === newHeaderKey
    })

    if (!headerExists)
      createdHeaders.push({ key: newHeaderKey, label: newHeaderLabel })
  })

  const certificateKey = 'Unit Certificate'
  const certificateHeaderLabel = 'Unit Certificate'
  const headerExists = createdHeaders.some(header => {
    return header.key === certificateKey
  })

  if (!headerExists)
    createdHeaders.push({
      key: certificateKey,
      label: certificateHeaderLabel,
    })
}

export const createModuleOrUnitHeaders = (
  createdHeaders,
  detail,
  detailType,
  index,
  newHeaderKey,
) => {
  let label

  if (detailType === 'moduleSela') {
    if (
      !newHeaderKey.includes('For School Principals') &&
      !newHeaderKey.includes('Meet With Your Leader Team') &&
      !newHeaderKey.includes('Getting Started') &&
      !newHeaderKey.includes('Kickoff')
    ) {
      label = `Unit ${index}`
    }
  }

  if (detailType === 'unitSela' && detail.type !== 'choiceCurriculum') {
    label = index + 1
  }
  const newHeaderLabel = label ? `${label}: ${newHeaderKey}` : newHeaderKey

  const headerExists = createdHeaders.some(header => {
    return header.key === newHeaderKey
  })

  if (!headerExists)
    createdHeaders.push({ key: newHeaderKey, label: newHeaderLabel })
}

export const createDataForMatchingDetail = (
  createdData,
  matchingDetail,
  user,
) => {
  const newData = {
    name: user.name,
    email: user.email,
    license_status: SELA_ACTIVE_STATUS[user.license_status],
    role: user.role,
    children_completed: matchingDetail.children_completed,
    total_children: matchingDetail.total_children,
  }

  const progress = !isNaN(matchingDetail.progress)
    ? `${Math.round(matchingDetail.progress * 100)}%`
    : NO_DATA_PLACEHOLDER

  assignModuleOrUnitData(matchingDetail, newData, progress)

  newData.progress = progress
  createdData.push(newData)
}

const assignModuleOrUnitData = (matchingDetail, newData, progress) => {
  matchingDetail?.user_children.forEach(child => {
    if (child.type === 'choiceCurriculum') {
      child.user_children?.forEach(meeting => {
        newData[`${meeting.display_title} - ${meeting.sub_content_type}`] =
          meeting.progress === 1 ? 'Y' : 'N'
      })
      newData['Unit Certificate'] = progress === '100%' ? 'Y' : 'N'
    } else {
      const unitOrLessonKey = child.display_title
      newData[unitOrLessonKey] =
        SELA_PROGRESS_STATUS[child.status] || NO_DATA_PLACEHOLDER
    }
  })
}

export const createDataForNoMatchingDetail = (createdData, headers, user) => {
  const newData = {
    name: user.name || NO_DATA_PLACEHOLDER,
    email: user.email,
    license_status: SELA_ACTIVE_STATUS[user.license_status],
    role: user.role,
  }

  headers.forEach(header => {
    const newHeader =
      header.key !== 'name' &&
      header.key !== 'email' &&
      header.key !== 'license_status' &&
      header.key !== 'role'

    if (newHeader) newData[header.key] = NO_DATA_PLACEHOLDER
  })

  createdData.push(newData)
}

export const compare = (a, b) => {
  if (a.license_status < b.license_status) return -1
  if (a.license_status > b.license_status) return 1

  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1

  return 0
}

export const sortGrades = grades => {
  return grades.sort((grade1, grade2) => {
    if (grade1.gradeName === 'Kindergarten') {
      return -1
    }
    if (grade2.gradeName === 'Kindergarten') {
      return 1
    }

    return grade1.gradeName.localeCompare(grade2.gradeName)
  })
}
