import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'
import { timezone } from 'utils/getTimeZone'
const baseApiAddress = `${domains.REPORTS_API}`

const PERIOD = 14
const PROGRAM = {
  HS: 'HS',
  K8: 'K8',
}

const api = (function(baseApiAddress, fakeout = false) {
  const _api = axios.create({
    baseUrl: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 60000,
  })

  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  const GET_ALL_USERS = {
    query: `
      query getAllUsers {
        user_dims(distinct: user_id) {
          name
          email
        }
      }
    `,
  }

  const selaReportContentTrackFragment = `
    fragment contentTrack on ContentChildren {
      contentful_id
      display_title
      type
      users_in_progress
      users_not_started
      users_completed
      users_completed_ids
      users_in_progress_ids
      users_not_started_ids
      total_users
    }
    `

  const selaReportUserChildrenFragment = `
    fragment userChildren on UserChildren {
      contentful_id
      display_title
      type
      progress
      status
      done_at
      children_completed
      total_children
      sub_content_type
    }
    `

  const selaReportContentTracksQuery = `
    content_tracks {
      display_title
      content_track
      content_children {
        ...contentTrack
        content_children {
          ...contentTrack
          content_children {
            ...contentTrack
            content_children {
              ...contentTrack
              content_children {
                ...contentTrack
              }
            }
          }
        }
      }
    }
    `

  const selaReportUserDetailsQuery = `
    user_details {
      user_id
      name
      email
      license_status
      instance_id
      role
      content_track
      user_children {
        ...userChildren
        user_children {
          ...userChildren
          user_children {
            ...userChildren
            user_children {
              ...userChildren
              user_children {
                ...userChildren
              }
            }
          }
        }
      }
    }
    `

  const GET_SELA_REPORT_DATA = licenseId => ({
    query: `
    ${selaReportContentTrackFragment}
    ${selaReportUserChildrenFragment}
    
    query {
      sela_report(license_id: ${licenseId}) {
        license_id
        users_totals {
          total
          license_statuses {
            license_status
            total
          }
        }

        ${selaReportContentTracksQuery}
        ${selaReportUserDetailsQuery}
      }
    }
    `,
  })

  const GET_SELA_REPORT_CONTENT_TRACKS = licenseId => ({
    query: `
    ${selaReportContentTrackFragment}
    
    query {
      sela_report(license_id: ${licenseId}) {
        license_id
        users_totals {
          total
          license_statuses {
            license_status
            total
          }
        }

        ${selaReportContentTracksQuery}
      }
    }
    `,
  })

  const GET_SELA_REPORT_USERS_DETAILS = licenseId => ({
    query: `
    ${selaReportUserChildrenFragment}
    
    query {
      sela_report(license_id: ${licenseId}) {
        license_id
        ${selaReportUserDetailsQuery}
      }
    }
    `,
  })

  // school view
  const GET_K8_REPORT_SCHOOL = (licenseId, timezone, period = 7) => ({
    query: `
      query SchoolView {
        SiteDetails(licenseId: ${licenseId}, period: ${period}, timeZone: "${timezone}", program: K8) {
          lastUpdatedAt
          period
          siteId
          totalClassesCount
          teachersCount
          administratorsCount
          siteName
          pendingInvitations
          numClassesWithCompletedLessonLastPeriod
          hasClassesWithCompletedLessonLastPeriod
          allGrades {
            gradeName
            classesCount
            numClassesWithCompletedLessonLastPeriod
            hasClassesWithCompletedLessonLastPeriod
          }
        }
      }
    `,
  })

  const GET_K8_REPORT_SCHOOL_BY_SITE = (
    siteId,
    timezone,
    period = 7,
    program = PROGRAM.K8,
  ) => {
    const variables = {
      siteId,
      period,
      timezone,
      program,
    }
    return {
      query: `
      query SchoolView($siteId: Int!, $timezone: TimeZone, $period: Int, $program: Program) {
        SiteDetails(period: $period, siteId: $siteId, timeZone: $timezone, program: $program) {
          lastUpdatedAt
          period
          siteId
          totalClassesCount
          teachersCount
          administratorsCount
          siteName
          pendingInvitations
          numClassesWithCompletedLessonLastPeriod
          hasClassesWithCompletedLessonLastPeriod
          allGrades {
            gradeName
            classesCount
            numClassesWithCompletedLessonLastPeriod
            hasClassesWithCompletedLessonLastPeriod
          }
        }
      }
    `,
      variables,
    }
  }

  const GET_ADMIN_K8_SITES_PAGINATED = (
    pageSize,
    pageNumber,
    orderBy,
    sortKey,
  ) => ({
    query: `
    query K8AdminSitePage {
      K8AdminSitesPaginated(pageSize: ${pageSize}, pageNumber: ${pageNumber}, orderBy: ${orderBy}, sortKey: ${sortKey}) {
        totalCount
        totalPages
        currentPage
        sites {
          siteName
          siteId
          totalUsersCount
          usersWithClassesCount
          usersWithoutClassesCount
          usersWithClassesLastUpdated
          adminsPendingCount
          adminsAcceptedCount
          teachersPendingCount
          teachersAcceptedCount
          classesCreatedLastUpdated
          classesCreated {
            total
            gradeK
            grade1
            grade2
            grade3
            grade4
            grade5
            grade6
            grade7
            grade8
          }
        }
      }
    }
    `,
  })

  const GET_ADMIN_K8_SITES_SUMMARY = () => ({
    query: `
      query AdminSitesSummary {
        AdminSitesSummary {
          totalSitesCount
          withoutClassesTotalCount
          classes {
            lastUpdated
            total
            gradeK
            grade1
            grade2
            grade3
            grade4
            grade5
            grade6
            grade7
            grade8
          }
          users {
            lastUpdated
            totalCount
            administrators{
              activeCount
              pendingCount
            }
            teachers{
              activeCount
              pendingCount
            }
            withClassesCount
          }
        }
      }
  `,
  })

  const GET_HS_LAUNCH_SNAPSHOT = adminEmail => {
    const variables = {
      adminEmail,
      program: PROGRAM.HS,
    }
    return {
      query: `
        query HsLaunchSnapshot($adminEmail: String!, $program: Program) {
          Schools (adminEmail: $adminEmail, program: $program) {
          school_name
          site_id
        }
        Users(adminEmail: $adminEmail, program: $program) {
          site_id
          role
          user_status
          user_id
        }
        SchoolTraining(adminEmail: $adminEmail, program: $program) { 
          site_id
          schoolwide_training_kickoff_status
          schoolwide_training_kickoff_completed_date
          training_program_completion_percent
          training_program_users_completed
          training_program_users_in_progress
          training_program_users_not_started
        }
        LastUpdatedAt {
          last_updated_at
        }
}
      `,
      variables,
    }
  }

  const GET_ADMIN_SELA_SITES_LIST = () => ({
    query: `
    query SelaAdminSitesList {
      SelaAdminSitesList {
        siteName
        licenseId              
      }
    }
    `,
  })
  // grade view
  const GET_K8_REPORT_GRADE = (
    licenseId,
    gradeFilter,
    timezone,
    period = 7,
  ) => ({
    query: `
      query GradeView {
        SiteDetails(licenseId: ${licenseId}, period: ${period}, timeZone: "${timezone}") {
          lastUpdatedAt
          period
          siteId
          allGrades {
            gradeName
            classesCount
            numClassesWithCompletedLessonLastPeriod
            hasClassesWithCompletedLessonLastPeriod
          }
          gradeDetails(filter: ${gradeFilter}) {
            classesCount
            gradeName
            hasClassesWithCompletedLessonLastPeriod
            numClassesWithCompletedLessonLastPeriod
            classes {
              classInstance
              className
              hasCompletedLessonLastPeriod
              lastLessonCompleted
              lastLessonCompletedDate
              lastLessonCompletedParent
              lastLessonCompletedTitle
              lessonCount
              lessonsCompleted
              lessonCountCore
              lessonsCompletedCore
              lessonCountUnit5
              lessonsCompletedUnit5
              teacherFirstName
              teacherLastName
            }
          }
        }
      }
    `,
  })
  const GET_K8_REPORT_GRADE_BY_SITE = (
    siteId,
    gradeFilter,
    timezone,
    period = 7,
  ) => ({
    query: `
      query GradeView {
        SiteDetails(period: ${period}, siteId: ${siteId}, timeZone: "${timezone}") {
          lastUpdatedAt
          period
          siteId
          allGrades {
            gradeName
            classesCount
            numClassesWithCompletedLessonLastPeriod
            hasClassesWithCompletedLessonLastPeriod
          }
          gradeDetails(filter: ${gradeFilter}) {
            classesCount
            gradeName
            hasClassesWithCompletedLessonLastPeriod
            numClassesWithCompletedLessonLastPeriod
            classes {
              classInstance
              className
              hasCompletedLessonLastPeriod
              lastLessonCompleted
              lastLessonCompletedDate
              lastLessonCompletedParent
              lastLessonCompletedTitle
              lessonCount
              lessonsCompleted
              lessonCountCore
              lessonsCompletedCore
              lessonCountUnit5
              lessonsCompletedUnit5
              teacherFirstName
              teacherLastName
            }
          }
        }
      }
    `,
  })

  // user lands on class details drawer, needs everything: grade view and class details
  const GET_K8_REPORT_GRADE_AND_CLASS_DETAILS = (
    licenseId,
    gradeFilter,
    classInstance,
    timezone,
    period = 7,
  ) => ({
    query: `
      query GradeAndClassDetailsView {
        ClassDetails(classInstance: "${classInstance}", licenseId: ${licenseId}) {
          classInstance
          gradeName
          className
          teacherFirstName
          teacherLastName
          lessonsCompleted
          lessonCount
          lessons {
            parent
            parentTitle
            lessonNumber
            lessonTitle
            lastViewedDate
            status
            completedDate
          }
        }
        SiteDetails(licenseId: ${licenseId}, period: ${period}, timeZone: "${timezone}") {
          lastUpdatedAt
          period
          siteId
          allGrades {
            gradeName
            classesCount
            numClassesWithCompletedLessonLastPeriod
            hasClassesWithCompletedLessonLastPeriod
          }
          gradeDetails(filter: ${gradeFilter}) {
            classesCount
            gradeName
            hasClassesWithCompletedLessonLastPeriod
            numClassesWithCompletedLessonLastPeriod
            classes {
              classInstance
              className
              hasCompletedLessonLastPeriod
              lastLessonCompleted
              lastLessonCompletedDate
              lastLessonCompletedParent
              lastLessonCompletedTitle
              lessonCount
              lessonsCompleted
              lessonCountCore
              lessonsCompletedCore
              lessonCountUnit5
              lessonsCompletedUnit5
              teacherFirstName
              teacherLastName
            }
          }
        }
      }
    `,
  })
  const GET_K8_REPORT_GRADE_AND_CLASS_DETAILS_BY_SITE = (
    siteId,
    gradeFilter,
    classInstance,
    timezone,
    period = 7,
  ) => ({
    query: `
      query GradeAndClassDetailsView {
        ClassDetails(classInstance: "${classInstance}", siteId: ${siteId}) {
          classInstance
          gradeName
          className
          teacherFirstName
          teacherLastName
          lessonsCompleted
          lessonCount
          lessons {
            parent
            parentTitle
            lessonNumber
            lessonTitle
            lastViewedDate
            status
            completedDate
          }
        }
        SiteDetails(period: ${period}, siteId: ${siteId}, timeZone: "${timezone}") {
          lastUpdatedAt
          period
          siteId
          allGrades {
            gradeName
            classesCount
            numClassesWithCompletedLessonLastPeriod
            hasClassesWithCompletedLessonLastPeriod
          }
          gradeDetails(filter: ${gradeFilter}) {
            classesCount
            gradeName
            hasClassesWithCompletedLessonLastPeriod
            numClassesWithCompletedLessonLastPeriod
            classes {
              classInstance
              className
              hasCompletedLessonLastPeriod
              lastLessonCompleted
              lastLessonCompletedDate
              lastLessonCompletedParent
              lastLessonCompletedTitle
              lessonCountCore
              lessonsCompletedCore
              lessonCountUnit5
              lessonsCompletedUnit5
              lessonCount
              lessonsCompleted
              teacherFirstName
              teacherLastName
            }
          }
        }
      }
    `,
  })
  // user opens details for class
  const GET_K8_REPORT_CLASS_DETAILS = (
    licenseId,
    classInstance,
    timezone,
    period = 7,
  ) => ({
    query: `
      query ClassDetailsView {
        ClassDetails(classInstance: "${classInstance}", licenseId: ${licenseId}) {
          classInstance
          gradeName
          className
          teacherFirstName
          teacherLastName
          lessonsCompleted
          lessonCount
          lessons {
            parent
            parentTitle
            lessonNumber
            lessonTitle
            lastViewedDate
            status
            completedDate
          }
        }
        SiteDetails(licenseId: ${licenseId}, period: ${period}, timeZone: "${timezone}") {
          lastUpdatedAt
          period
          siteId
        }
      }
    `,
  })
  const GET_K8_REPORT_CLASS_DETAILS_BY_SITE = (
    siteId,
    classInstance,
    timezone,
    period = 7,
  ) => ({
    query: `
      query ClassDetailsView {
        ClassDetails(classInstance: "${classInstance}", siteId: ${siteId}) {
          classInstance
          gradeName
          className
          teacherFirstName
          teacherLastName
          lessonsCompleted
          lessonCount
          lessons {
            parent
            parentTitle
            lessonNumber
            lessonTitle
            lastViewedDate
            status
            completedDate
          }
        }
        SiteDetails(period: ${period}, siteId: ${siteId}, timeZone: "${timezone}") {
          lastUpdatedAt
          period
          siteId
        }
      }
    `,
  })

  const GET_LESSON_COMPLETION_DATA = (adminEmail, siteId, program) => {
    const variables = {
      adminEmail,
      siteId,
      program,
    }
    return {
      query: `
    query LessonCompletionWholeQuery($adminEmail: String, $siteId: Int, $program: Program) {
      Schools (adminEmail: $adminEmail, siteId: $siteId, program: $program) {
        school_name
        classes_created
        classes_started
        schoolwide_lesson_completion_percentage
        site_id
        lessons_marked_done
        total_lessons
      }
      UnitSummary (adminEmail: $adminEmail, siteId: $siteId, program: $program) {
        unit_number
        grade
        completion_percentage
        lessons_marked_done
        total_lessons
        site_id
        class_count
        classes_started
      }
      LastUpdatedAt {
        last_updated_at
      }
    }
    `,
      variables,
    }
  }

  return {
    getLessonCompletionData: async (
      adminEmail = null,
      siteId = null,
      program,
    ) => {
      return _api.post(
        baseApiAddress,
        GET_LESSON_COMPLETION_DATA(adminEmail, siteId, program),
      )
    },
    getAllUsers: async () => {
      return _api.post(baseApiAddress, GET_ALL_USERS)
    },
    getReportsData: async licenseId => {
      return _api.post(baseApiAddress, GET_SELA_REPORT_DATA(licenseId))
    },
    getK8ReportSchool: async licenseId => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_SCHOOL(licenseId, timezone(), PERIOD),
      )
    },
    getK8ReportSchoolBySiteId: async siteId => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_SCHOOL_BY_SITE(siteId, timezone(), PERIOD, PROGRAM.K8),
      )
    },
    getAdminHsLaunchSnapshot: async adminEmail => {
      return _api.post(baseApiAddress, GET_HS_LAUNCH_SNAPSHOT(adminEmail))
    },
    getAdminSelaSitesList: async () => {
      return _api.post(baseApiAddress, GET_ADMIN_SELA_SITES_LIST())
    },
    getK8ReportGrade: async (licenseId, grade) => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_GRADE(licenseId, grade, timezone(), PERIOD),
      )
    },
    getK8ReportGradeBySiteId: async (siteId, grade) => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_GRADE_BY_SITE(siteId, grade, timezone(), PERIOD),
      )
    },
    getK8ReportGradeAndClassDetails: async (
      licenseId,
      grade,
      classInstance,
    ) => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_GRADE_AND_CLASS_DETAILS(
          licenseId,
          grade,
          classInstance,
          timezone(),
          PERIOD,
        ),
      )
    },
    getK8ReportGradeAndClassDetailsBySiteId: async (
      siteId,
      grade,
      classInstance,
    ) => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_GRADE_AND_CLASS_DETAILS_BY_SITE(
          siteId,
          grade,
          classInstance,
          timezone(),
          PERIOD,
        ),
      )
    },
    getK8ReportClassDetails: async (licenseId, classInstance) => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_CLASS_DETAILS(
          licenseId,
          classInstance,
          timezone(),
          PERIOD,
        ),
      )
    },
    getK8ReportClassDetailsBySiteId: async (siteId, classInstance) => {
      return _api.post(
        baseApiAddress,
        GET_K8_REPORT_CLASS_DETAILS_BY_SITE(
          siteId,
          classInstance,
          timezone(),
          PERIOD,
        ),
      )
    },
    getReportsContentTrack: async licenseId => {
      return _api.post(
        baseApiAddress,
        GET_SELA_REPORT_CONTENT_TRACKS(licenseId),
      )
    },
    getReportsUsersDetail: async licenseId => {
      return _api.post(baseApiAddress, GET_SELA_REPORT_USERS_DETAILS(licenseId))
    },
    getAdminK8SitesPaginated: async (
      pageSize,
      pageNumber,
      orderBy,
      sortKey,
    ) => {
      return _api.post(
        baseApiAddress,
        GET_ADMIN_K8_SITES_PAGINATED(pageSize, pageNumber, orderBy, sortKey),
      )
    },
    getAdminK8SitesSummary: async () => {
      return _api.post(baseApiAddress, GET_ADMIN_K8_SITES_SUMMARY())
    },
  }
})(baseApiAddress, true)

export default api
