import { FlagsComponentRouter, FlagsSingleton } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'
import {
  isHighSchoolFamilyResources,
  isMiddleSchoolProductJuneRelease,
  isNewFamilyResources,
} from 'utils/productHelpers'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: [
    'middle-school-june-release',
    'feature-learn-17909-program-flex-family-resources',
    'feature_LEARN-18248-hs-family-resources',
  ],
  OnComponent,
  OffComponent,
  flagsChecker: FlagsSingleton.checkAtLeastOneFlagTrue,
  onCondition: (_, subscribedFlags) =>
    isMiddleSchoolProductJuneRelease(_, subscribedFlags) ||
    isNewFamilyResources(_, subscribedFlags) ||
    isHighSchoolFamilyResources(_, subscribedFlags),
})

export default FlagToggler
