import AdminSortButton from 'components/AdminSortButton'
import LeftBox from 'components/SortableAccordionList/flag.on/SortableAccordionLeftBox'
import { SortOrder } from 'components/SortableAccordionList/flag.on/constants'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import { TOOLTIP_LOCATIONS } from 'utils/tooltipHelpers'
import LaunchListItem from './LaunchListItem'
import { StyledSortableAccordionList } from './component.styles'
import {
  PENDING_COPY,
  SCHOOLS_COPY,
  SETUP_COPY,
  SITE_HEADER,
  USERS_COPY,
} from './constants'

const renderLeftBox = summary => {
  const { schoolsRequiringSetup, totalPendingCount, totalSchools, totalUsers } =
    summary || {}
  const props = {
    header: SITE_HEADER,
    subHeaderRows: [
      {
        label: summary.totalSchools,
        copy: SCHOOLS_COPY(totalSchools),
      },
      { label: totalUsers, copy: USERS_COPY(totalUsers) },
      {
        label: totalPendingCount,
        copy: PENDING_COPY(totalPendingCount),
      },
    ],
    bottomHeader: schoolsRequiringSetup
      ? SETUP_COPY(schoolsRequiringSetup)
      : null,
  }
  return <LeftBox {...props} />
}

export const AdminHSMultiSiteContainer = ({
  adminEmail,
  fetchHsLaunchSnapshot,
  isFetching,
  selectedProgramKey,
  sites,
  sitesPerPage,
  summary,
  totalPages,
}) => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  const [sortKey, setSortKey] = useState('school')
  const [sortOrder, setSortOrder] = useState(SortOrder.asc)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (!sites) {
      fetchHsLaunchSnapshot(adminEmail)
    }
  }, [sites])

  useEffect(() => {
    searchParams.set('sortKey', sortKey)
    searchParams.set('sortOrder', sortOrder)
    searchParams.set('pageNumber', currentPage)
    history.replace({ search: searchParams.toString() })
  }, [sortKey, sortOrder, currentPage])

  const handleSort = newSortKey => {
    if (sortKey === newSortKey) {
      setSortOrder(sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc)
    } else {
      setSortKey(newSortKey)
      setSortOrder(SortOrder.asc)
    }
  }

  // TODO: this component is tied to launch context, refactor using context provider or hoc
  const sortButtonData = [
    {
      sortKey: 'school',
      title: 'School',
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.LAUNCH_SITE,
    },
    {
      sortKey: 'users',
      title: 'Users Added',
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.LAUNCH_USERS,
    },
    {
      sortKey: 'training',
      title: 'Staff Training Completion',
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.LAUNCH_TRAINING_COMPLETION,
    },
  ]

  return (
    <StyledSortableAccordionList
      currentItemCount={sites?.length}
      currentPage={currentPage}
      handlePageChange={setCurrentPage}
      isFetching={isFetching}
      itemLabel="School"
      items={sites}
      itemsPerPage={sitesPerPage}
      renderLeftBox={() => renderLeftBox(summary)}
      renderSortButton={props => (
        <AdminSortButton {...props} programKey={selectedProgramKey} />
      )}
      showHairline={true}
      sortButtons={sortButtonData}
      sortKey={sortKey}
      sortOrder={sortOrder}
      totalItems={summary?.totalSchools}
      totalPages={totalPages}
    >
      {(site, index, isOpen, handleListItemClick) => {
        const props = {
          site,
          index,
          isMultiSite: true,
          isOpen,
          handleListItemClick,
          selectedProgramKey,
        }
        return <LaunchListItem {...props} />
      }}
    </StyledSortableAccordionList>
  )
}

const mapStateToProps = state => {
  const {
    reportsManager: {
      selectedProgram,
      admin: {
        hs: {
          isFetching,
          launchPagination: { sitesPerPage, totalCount, totalPages },
        },
      },
    },
    userProfileManager: {
      profile: { email },
    },
  } = state
  // TODO: this component is tied to launch context, refactor using context provider or hoc
  const hsSitesWithPreferences = reportsSelectors.selectSortedAdminHsLaunchSnapshotSites(
    state,
  )
  const launchSnapshotSummary = reportsSelectors.selectAdminHsLaunchSnapshotSummary(
    state,
  )
  return {
    adminEmail: email,
    selectedProgramKey: selectedProgram?.key,
    sites: hsSitesWithPreferences,
    sitesPerPage,
    summary: launchSnapshotSummary,
    totalCount,
    totalPages,
    isFetching,
  }
}

const mapDispatchToProps = {
  fetchHsLaunchSnapshot: reportsOperations.fetchHsLaunchSnapshot,
}

AdminHSMultiSiteContainer.propTypes = {
  adminEmail: PropTypes.string,
  fetchHsLaunchSnapshot: PropTypes.func,
  isFetching: PropTypes.bool,
  selectedProgramKey: PropTypes.string,
  sites: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string,
        siteId: PropTypes.number,
        users: PropTypes.array,
        preferences: PropTypes.shape({
          areUsersAdded: PropTypes.bool,
          implementationLevel: PropTypes.number,
          implementationType: PropTypes.string,
          isLaunched: PropTypes.bool,
          isSetup: PropTypes.bool,
          studentActivities: PropTypes.bool,
        }),
        adminsPendingCount: PropTypes.number,
        teachersPendingCount: PropTypes.number,
        totalUsersCount: PropTypes.number,
      }),
    ), // fully hydrated
    PropTypes.object, // fetching or error
    PropTypes.oneOf([null]), // not hydrated
  ]),
  sitesPerPage: PropTypes.number,
  summary: PropTypes.shape({
    totalSchools: PropTypes.number,
    schoolsRequiringSetup: PropTypes.number,
    totalUsers: PropTypes.number,
  }),
  totalPages: PropTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminHSMultiSiteContainer, [ERROR_TYPE_REPORTING]))
