export const ELEMENTARY_ROUTE = 'elementary'
export const FAMILY_MIND_YETI_ROUTE = 'family-mind-yeti'
export const FAMILY_RESOURCES_ROUTE = 'family-resources'
export const FAIMLY_RESOURCES_HS_ROUTE = 'family'
export const MIDDLE_SCHOOL_ROUTE = 'middle-school'
export const SELA_ROUTE = 'sel-for-adults'
export const FT_ROUTE = 'sel-for-adults-field-test'
export const K8_ROUTE = 'k8'
export const SELA_MEMBER = 'Sela'
export const FT_MEMBER = 'SelaFT'
