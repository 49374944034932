import React from 'react'
import PropTypes from 'prop-types'
import CourseComponent from 'components/CourseComponent'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import UnitComponent from 'components/UnitComponent'
import { HeadingSpacer } from './component.styles'
import { UnitType } from 'components/UnitComponent/types'
import {
  lessonsAmountsType,
  scopeAndSequenceLinksType,
} from 'experienceComponents/CourseHeader'

export const Component = props => {
  const {
    acknowledgements,
    match,
    onSelectUnit,
    productName,
    scopeAndSequenceData,
    title,
    lessonsAmounts,
    unitId,
    unit,
  } = props

  return (
    <LearnErrorBoundary>
      <HeadingSpacer />
      <CourseComponent
        displayTitle=""
        isActive={false}
        isEditable={false}
        lessonsAmounts={lessonsAmounts}
        scopeAndSequenceLinks={scopeAndSequenceData}
        skipHead={true}
        title={title}
      >
        <UnitComponent
          acknowledgements={acknowledgements}
          match={match}
          onSelectUnit={onSelectUnit}
          productName={productName}
          unit={unit}
          unitId={unitId}
        />
      </CourseComponent>
    </LearnErrorBoundary>
  )
}

Component.propTypes = {
  acknowledgements: PropTypes.shape({
    contentType: PropTypes.string,
    description: PropTypes.string,
    displayTitle: PropTypes.string,
    id: PropTypes.string,
    internalTitle: PropTypes.string,
    locale: PropTypes.string,
    pageData: PropTypes.string,
    type: PropTypes.string,
  }),
  lessonsAmounts: lessonsAmountsType,
  match: PropTypes.object.isRequired,
  onSelectUnit: PropTypes.func.isRequired,
  productName: PropTypes.string,
  scopeAndSequenceData: scopeAndSequenceLinksType,
  title: PropTypes.string.isRequired,
  unit: UnitType.isRequired,
  unitId: PropTypes.string.isRequired,
}

export default Component
