import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { filterUniqueUserContextFields } from 'auth/stores/userContext'
import { operations, selectors } from 'store/contentServiceManager'
import { useUserContext } from 'layers/content/v2/hooks/useUserContext'

export function useContentGetter({
  entryId: entryIdInput,
  queryType,
  locale,
  isPreview,
  lazy = false,
  filterUserContext = filterUniqueUserContextFields,
}) {
  // little hack to make entryId dynamic in some components (see LessonV2/flag.on/container)
  const resolveEntryId = () => {
    if (typeof entryIdInput === 'function') {
      return entryIdInput()
    }
    return entryIdInput
  }
  const entryId = resolveEntryId()

  const dispatch = useDispatch()
  const userContext = useUserContext()
  const currentFilteredUserContext = filterUserContext(userContext)

  const { data, error, loading, prevFetchedUserContext } = useSelector(
    selectors.selectContentById(entryId, locale),
  )

  const fetch = () => {
    dispatch(
      operations.fetchContent({
        entryId,
        queryType,
        locale,
        isPreview,
        userContext: currentFilteredUserContext,
      }),
    )
  }

  const stringCurrentUserContext = JSON.stringify(
    currentFilteredUserContext || {},
  )

  useEffect(() => {
    const contentExist = !error && !!entryId && !!queryType && !!data
    const userContextIsNotTheSame =
      stringCurrentUserContext !== undefined &&
      prevFetchedUserContext !== undefined &&
      stringCurrentUserContext !== prevFetchedUserContext
    // if we had a previous fetch but the user context is different, we need to fetch again
    if (contentExist && userContextIsNotTheSame) {
      fetch()
    }

    if (!userContext || lazy || contentExist) {
      return
    }

    fetch()
  }, [stringCurrentUserContext, entryId, queryType])

  return {
    data,
    error,
    loading,
    fetch,
  }
}
