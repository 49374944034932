import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { operations as lmsOperations } from 'store/lmsManager'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'
import { operations as loadingOperations } from 'store/loadingManager'
import {
  operations as userSessionOperations,
  selectors as userSessionSelectors,
} from 'store/userSessionManager'

import NavigationHelper from 'layers/navigation/navigationHelper'
import { GetRootContent } from 'layers/content/Hocs/RootContent'
import withApiError, {
  SHOW_CONTENT_ON_401,
  SHOW_ERROR_IN_MODAL,
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_ACCESS,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import { ROLES } from 'config/constants'
import { isFlagOn } from 'utils/featureFlags'
import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'

import {
  DASHBOARD_URL,
  LOADING_TEXT_DASHBOARD,
  PROGRAM_FIELD_TEST,
} from './constants'
import Dashboard from './component'

export class DashboardContainer extends Component {
  static propTypes = {
    activeDigitalLicenses: PropTypes.array,
    addedPAKs: PropTypes.array,
    contentfulPrograms: PropTypes.array,
    createFTPathInstance: PropTypes.func,
    createSelaPathInstance: PropTypes.func.isRequired,
    fetchActiveDigitalLicensesForUser: PropTypes.func.isRequired,
    fetchInstructorData: PropTypes.func.isRequired,
    fetchInstructorDataV2: PropTypes.func.isRequired,
    fetchSeedIds: PropTypes.func.isRequired,
    fetchSetupLicensesForUser: PropTypes.func.isRequired,
    getEntry: PropTypes.func,
    instructorData: PropTypes.object,
    isContentfulRefetching: PropTypes.bool,
    isFetchingActiveDigitalLicenses: PropTypes.bool,
    isFetchingInstructorData: PropTypes.bool.isRequired,
    isFetchingInstructorDataV2: PropTypes.bool.isRequired,
    isFetchingSetupLicenses: PropTypes.bool,
    isLicenseManagerSending: PropTypes.bool,
    isRefetching: PropTypes.bool,
    licenseError: PropTypes.shape({
      response: PropTypes.shape({
        data: PropTypes.string,
        status: PropTypes.number,
        statusText: PropTypes.string,
      }),
    }),
    lmsError: PropTypes.shape({
      response: PropTypes.shape({
        data: PropTypes.string,
        status: PropTypes.number,
        statusText: PropTypes.string,
      }),
    }),
    match: PropTypes.object.isRequired,
    navigationEvent: PropTypes.func,
    pageTitle: PropTypes.string,
    programGroup: PropTypes.array,
    recentDashboardTab: PropTypes.string,
    seedIds: PropTypes.object,
    setAppIsLoadingState: PropTypes.func,
    setAppNotLoadingState: PropTypes.func,
    setRecentDashboardTab: PropTypes.func,
    trainingPaths: PropTypes.array,
    unassignedLicenses: PropTypes.array,
    userAccessClaims: PropTypes.object,
    userContext: PropTypes.object,
    userProfile: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.shouldUpdateLoadingState = this.shouldUpdateLoadingState.bind(this)
  }

  componentDidMount() {
    const {
      fetchActiveDigitalLicensesForUser,
      fetchInstructorData,
      fetchInstructorDataV2,
      fetchSetupLicensesForUser,
      fetchSeedIds,
      seedIds,
      userProfile,
      instructorData,
      isFetchingActiveDigitalLicenses,
      isFetchingSetupLicenses,
      setAppIsLoadingState,
      unassignedLicenses,
      userContext,
    } = this.props
    const { roles } = userProfile || {}
    const isUserSetupAdmin = roles?.includes(ROLES.SETUP_ADMIN)
    const shouldCallLMSV2 = isFlagOn(['middle-school-june-release'])

    let readyToLoad =
      !instructorData ||
      isFetchingSetupLicenses ||
      isFetchingActiveDigitalLicenses

    if (shouldCallLMSV2) {
      readyToLoad =
        !instructorData?.Classes ||
        !instructorData?.MiddleSchool ||
        !instructorData?.Sela ||
        isFetchingActiveDigitalLicenses ||
        isFetchingSetupLicenses
    }

    if (readyToLoad) {
      setAppIsLoadingState(LOADING_TEXT_DASHBOARD)
    }

    if (isUserSetupAdmin && !isFetchingSetupLicenses && !unassignedLicenses) {
      fetchSetupLicensesForUser()
    }

    fetchActiveDigitalLicensesForUser()
    fetchInstructorData()

    if (shouldCallLMSV2) {
      fetchInstructorDataV2(userContext)
    }

    if (!seedIds) {
      fetchSeedIds()
    }
  }

  componentDidUpdate(prevProps) {
    const {
      addedPAKs,
      getEntry,
      instructorData,
      isFetchingInstructorData,
      isFetchingInstructorDataV2,
      userAccessClaims,
      contentfulPrograms,
    } = this.props

    if (
      instructorData &&
      instructorData?.Sela &&
      userAccessClaims &&
      contentfulPrograms &&
      !isFetchingInstructorData &&
      !isFetchingInstructorDataV2
    ) {
      this.createSelaPath()
      this.createFTPath()
    }

    if (addedPAKs?.length > prevProps.addedPAKs?.length) {
      getEntry()
    }

    this.ifDashboardPathAddProgramRoute()
    this.shouldUpdateLoadingState()
  }

  componentWillUnmount() {
    const { isLoadingApp, setAppNotLoadingState } = this.props || {}
    if (isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  shouldUpdateLoadingState() {
    const {
      instructorData,
      isFetchingActiveDigitalLicenses,
      isFetchingSetupLicenses,
      setAppIsLoadingState,
      setAppNotLoadingState,
      isLoadingApp,
    } = this.props || {}

    const shouldCallLMSV2 = isFlagOn(['middle-school-june-release'])

    let readyToLoad =
      !instructorData ||
      isFetchingSetupLicenses ||
      isFetchingActiveDigitalLicenses

    if (shouldCallLMSV2) {
      readyToLoad =
        !instructorData?.Classes ||
        !instructorData?.MiddleSchool ||
        !instructorData?.Sela ||
        !instructorData?.HighSchool ||
        isFetchingActiveDigitalLicenses ||
        isFetchingSetupLicenses
    }

    if (readyToLoad && !isLoadingApp) {
      setAppIsLoadingState(LOADING_TEXT_DASHBOARD)
    }
    if (!readyToLoad && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  createSelaPath = () => {
    const {
      userAccessClaims,
      instructorData,
      createSelaPathInstance,
      contentfulPrograms,
    } = this.props
    const { hasSelaLeaderClaim, hasSelaStaffClaim } = userAccessClaims
    const hasSelaRole = hasSelaLeaderClaim || hasSelaStaffClaim
    const hasSelaInContentful =
      Array.isArray(contentfulPrograms) &&
      contentfulPrograms.some(program => program.internalTitle === 'SELA')

    if (hasSelaRole && hasSelaInContentful) {
      const { Sela: selaPath } = instructorData || {}
      const totalSelaPaths = selaPath?.length || 0

      if (totalSelaPaths === 0) {
        createSelaPathInstance()
      }
    }
  }

  createFTPath = () => {
    const {
      userAccessClaims,
      instructorData,
      createFTPathInstance,
      contentfulPrograms,
    } = this.props
    const { hasFTLeaderClaim, hasFTStaffClaim } = userAccessClaims

    const hasFTAccessClaim = hasFTLeaderClaim || hasFTStaffClaim
    const hasFTInContentful =
      Array.isArray(contentfulPrograms) &&
      contentfulPrograms.some(
        program => program.internalTitle === PROGRAM_FIELD_TEST,
      )

    if (hasFTAccessClaim && hasFTInContentful) {
      const { SelaFT: ftPath } = instructorData || {}
      const totalFTPaths = ftPath?.length || 0

      if (totalFTPaths === 0) {
        createFTPathInstance()
      }
    }
  }

  setDefaultTab = programs => {
    const {
      activeDigitalLicenses,
      recentDashboardTab,
      setRecentDashboardTab,
      userAccessClaims,
    } = this.props
    const { checkIfUserHasMSSiteWideLicenseAndAccess } = userSessionSelectors
    let defaultTab

    if (recentDashboardTab) {
      defaultTab = recentDashboardTab
    } else {
      const availablePrograms = removeRedactedContentfulArray(programs)
      const hasMiddleSchoolSiteWideLicense = checkIfUserHasMSSiteWideLicenseAndAccess(
        activeDigitalLicenses,
        userAccessClaims,
      )
      const programsAreAvailable = availablePrograms?.length > 0
      const defaultProgram =
        hasMiddleSchoolSiteWideLicense && programsAreAvailable
          ? availablePrograms.filter(
              program => program.internalTitle === 'ms tab',
            )[0]
          : availablePrograms[0] || {}

      defaultTab = defaultProgram?.route
      defaultTab && setRecentDashboardTab(defaultTab)
    }

    return defaultTab
  }

  ifDashboardPathAddProgramRoute = () => {
    const {
      activeDigitalLicenses,
      navigationEvent,
      programGroup,
      match,
      recentDashboardTab,
      setRecentDashboardTab,
    } = this.props

    if (!Array.isArray(activeDigitalLicenses)) {
      return null
    }
    if (programGroup && match.path === DASHBOARD_URL) {
      const defaultTab = this.setDefaultTab(programGroup)

      if (defaultTab) {
        navigationEvent(`${DASHBOARD_URL}/${defaultTab}`, 'REPLACE')
      }
    } else {
      const currentTab = match?.params?.tabName
      const shouldDashboardTabUpdate = currentTab !== recentDashboardTab
      shouldDashboardTabUpdate && setRecentDashboardTab(currentTab)
    }
  }

  userHasUnassignedLicenses = () => {
    const { userProfile, unassignedLicenses } = this.props
    const { roles } = userProfile || {}
    const hasLicenses =
      roles?.includes(ROLES.SETUP_ADMIN) &&
      unassignedLicenses &&
      unassignedLicenses.length > 0
    return hasLicenses
  }

  render() {
    const {
      activeDigitalLicenses,
      contentfulPrograms,
      instructorData,
      isFetchingSetupLicenses,
      isLicenseManagerSending,
      isContentfulRefetching,
      isRefetching,
      match,
      navigationEvent,
      pageTitle,
      programGroup = [],
      seedIds = {},
      trainingPaths = [],
      unassignedLicenses,
      userProfile,
    } = this.props

    const hasUnassignedLicenses = this.userHasUnassignedLicenses()
    const { Classes, Elementary, Trainings, Sela, SelaFT } =
      instructorData || {}
    const msFlag = isFlagOn(['middle-school-june-release'])

    const getCurrentProgram = () => {
      // TODO: LEARN-8666 REMOVE THIS AFTER FTUE IS DEFINED
      // DEFAULTS TO MIDDLE SCHOOL
      let classes = Classes
      const isMsProgram = match?.params?.tabName?.includes('6-8')
      const isElemProgram = match?.params?.tabName?.includes('5')

      if (isElemProgram) {
        classes = Elementary
      } else if (isMsProgram) {
        if (msFlag) {
          classes = instructorData?.MiddleSchool
        } else {
          classes = Classes
        }
      }
      return classes
    }

    const classesByProgram = getCurrentProgram()
    const showDashboard =
      instructorData &&
      !isFetchingSetupLicenses &&
      Array.isArray(activeDigitalLicenses)

    const contentfulProgramsAvailable = removeRedactedContentfulArray(
      contentfulPrograms,
    )
    const showContentfulLoader =
      !contentfulProgramsAvailable && isContentfulRefetching

    if (showContentfulLoader || isLicenseManagerSending) {
      return null
    }

    return [
      showDashboard && (
        <Dashboard
          classes={classesByProgram}
          contentfulPrograms={contentfulPrograms}
          hasUnassignedLicenses={hasUnassignedLicenses}
          isContentfulRefetching={isRefetching}
          isLicenseManagerSending={isLicenseManagerSending}
          lmsFTPath={SelaFT}
          lmsSelaPath={Sela}
          lmsTrainings={Trainings}
          match={match}
          navigationEvent={navigationEvent}
          pageTitle={pageTitle}
          programGroup={programGroup}
          seedIds={seedIds}
          trainingPaths={trainingPaths}
          unassignedLicenses={unassignedLicenses}
          userProfile={userProfile}
        />
      ),
    ]
  }
}

const mapStateToProps = state => {
  const {
    lmsManager,
    licenseManager,
    userAccessManager,
    userContextManager,
    userProfileManager,
    userSessionManager,
    loadingManager,
  } = state
  return {
    activeDigitalLicenses: licenseSelectors.selectActiveDigitalLicenses(state),
    addedPAKs: licenseManager.addedPAKs,
    instructorData: lmsManager.instructorData,
    isFetchingActiveDigitalLicenses:
      licenseManager.isFetchingActiveDigitalLicenses,
    isFetchingInstructorData: lmsManager.isFetching,
    isFetchingInstructorDataV2: lmsManager.isFetchingV2,
    isFetchingSetupLicenses: licenseManager.isFetchingSetupLicenses,
    isLicenseManagerSending: licenseManager.isSending,
    isLoadingApp: loadingManager?.isLoading,
    recentDashboardTab: userSessionManager.programDashboard.recentDashboardTab,
    seedIds: lmsManager.seedIds,
    trainingPaths: lmsManager.trainingPaths,
    unassignedLicenses: licenseManager.unassignedLicenses,
    userAccessClaims: userAccessManager.claims,
    userContext: userContextManager,
    userProfile: userProfileManager.profile,
  }
}

const mapDispatchToProps = {
  createSelaPathInstance: lmsOperations.createSelaPathInstance,
  createFTPathInstance: lmsOperations.createFTPathInstance,
  fetchActiveDigitalLicensesForUser:
    licenseOperations.fetchActiveDigitalLicensesForUser,
  fetchInstructorData: lmsOperations.fetchInstructorData,
  fetchInstructorDataV2: lmsOperations.fetchInstructorDataV2,
  fetchSeedIds: lmsOperations.fetchSeedIds,
  fetchSetupLicensesForUser: licenseOperations.fetchSetupLicensesForUser,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
  setRecentDashboardTab:
    userSessionOperations.programDashboardSetRecentDashboardTab,
}

export const mapper = entry => {
  const {
    displayTitle: pageTitle,
    products: contentfulPrograms,
    programGroup,
  } = entry || {}
  return { contentfulPrograms, pageTitle, programGroup }
}

const options = {
  include: 3,
  mapper,
  spread: true,
}

export default GetRootContent(
  withApiError(
    DashboardContainer,
    [ERROR_TYPE_CONTENTFUL, ERROR_TYPE_LMS, ERROR_TYPE_ACCESS],
    [SHOW_CONTENT_ON_401, SHOW_ERROR_IN_MODAL],
  ),
  options,
  connect(mapStateToProps, mapDispatchToProps),
  connect(null, NavigationHelper.mapDispatchToProps),
)
