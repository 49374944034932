import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DownloadIcon, SpinnerAnimatedIcon } from 'secondstep-components'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import {
  BUTTON_TEXT_LAUNCH,
  BUTTON_TEXT_LOADING,
  BUTTON_TEXT_MONITOR,
} from './constants'
import {
  ExportButtonWrapper,
  StyledAdminSearchSelect,
  StyledAdminTooltip,
} from './component.styles'

const AdminExportDownloadButton = ({
  className,
  clearDocumentUrl,
  documentUrl,
  fetchDocumentUrl,
  isFetching,
  programKey,
  selectedProgram,
  toggleView,
  tooltipLocation,
  userEmail,
}) => {
  useEffect(() => {
    if (documentUrl) {
      const downloadLink = document.createElement('a')
      downloadLink.href = documentUrl
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      clearDocumentUrl()
    }
  }, [documentUrl])

  const Icon = isFetching ? <SpinnerAnimatedIcon /> : <DownloadIcon />
  const label = isFetching
    ? BUTTON_TEXT_LOADING
    : toggleView === TOGGLE_VIEW.MONITOR
    ? BUTTON_TEXT_MONITOR
    : BUTTON_TEXT_LAUNCH

  return (
    <ExportButtonWrapper className={className}>
      <StyledAdminSearchSelect
        dataTestId="admin-export-download"
        defaultLabel={label}
        disabled={isFetching}
        dropdownButtonIcon={Icon}
        isFetching={isFetching}
        onChange={() => {
          fetchDocumentUrl({ userEmail, program: selectedProgram })
        }}
        options={[{ name: 'Current Year (.xlsx)', key: '1' }]}
      />
      <StyledAdminTooltip
        location={tooltipLocation}
        monitorView
        programKey={programKey}
      />
    </ExportButtonWrapper>
  )
}

AdminExportDownloadButton.propTypes = {
  className: PropTypes.string,
  clearDocumentUrl: PropTypes.func,
  documentUrl: PropTypes.string,
  fetchDocumentUrl: PropTypes.func,
  isFetching: PropTypes.bool,
  programKey: PropTypes.string,
  selectedProgram: PropTypes.string,
  toggleView: PropTypes.string,
  tooltipLocation: PropTypes.string,
  userEmail: PropTypes.string,
}

export default AdminExportDownloadButton
