import { ContainedButton, Hairline } from 'secondstep-components'
import styled from 'styled-components'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'
import { themeGet } from 'styled-system'

const breakpoints = {
  customTablet: '@media screen and (max-width: 860px)',
}

export const FooterWrapper = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.5rem;
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${breakpoints.customTablet} {
    flex-direction: column;
  }
`

export const LargeBullet = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: ${themeGet('colors.ost6')};
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 550px;

  svg {
    color: ${themeGet('colors.darkBlue')};
  }

  p {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }
`

export const PaginationButton = styled(ContainedButton)`
  // breaking from theme, roll an admin theme after the design dust settles?
  :disabled {
    background: ${themeGet('colors.white')};
    color: ${themeGet('colors.darkGray')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
  }
`

export const PaginationIndexWrapper = styled.div`
  color: ${themeGet('colors.darkGray')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  gap: 0.625rem;
`

export const PaginationItemWrapper = styled.div``

export const PaginationPageWrapper = styled.div`
  font-family: ${themeGet('font.molde.medium')};
`

export const RightBox = styled.div`
  display: flex;
`

export const StyledAdminExportDownloadButton = styled(
  AdminExportDownloadButton,
)`
  align-items: center;
  margin-top: 0.75rem;

  ${breakpoints.customTablet} {
    margin-bottom: 0.4375rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1.25rem;
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin-top: 0.8125rem;
  margin-bottom: 1.25rem;
  background-color: ${themeGet('colors.paleGray')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 0;
  }
`

export const TableWrapper = styled.div`
  @media (max-width: 759px) {
    display: none;
  }
`

export const ViewOnDesktopMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6.5rem 0 7.75rem;

  p {
    font-size: 1.125rem;
    margin-top: 1.0625rem;
  }

  @media (min-width: 760px) {
    display: none;
  }
`

export const Wrapper = styled.div`
  background: ${themeGet('colors.white')};
  border-radius: 0.625rem;
  padding: 1.5rem 1.25rem 1.25rem;
`
