import React from 'react'
import PropTypes from 'prop-types'
import { useSubNavGetter } from 'layers/content/v2/hooks/useSubNavGetter'

export default function SubNavGetter(Component, options) {
  function SubNavGetterComponent(props) {
    function resolveApp() {
      // options.app can be a function to fetch or a string
      // supporting a function ensures we can dynamically update app as page changes
      const { app } = options
      if (typeof app === 'function') {
        return app()
      }
      return app
    }

    const subNavData = useSubNavGetter({
      ...options,
      app: resolveApp(),
    })

    return <Component {...props} subNavData={subNavData} />
  }

  return SubNavGetterComponent
}

export const SubNavGetterComponentPropTypes = PropTypes.shape({
  subNavData: PropTypes.shape({
    data: PropTypes.array,
    error: PropTypes.object,
    loading: PropTypes.bool,
  }),
})
