import {
  DashboardIcon,
  HelpIcon,
  NotificationsIcon,
  ProfileIcon,
} from 'secondstep-components'

export const ADMIN_DASHBOARD_LINK = '/admin'
export const LOGO_LINK = `/dashboard`
export const PROFILE = 'PROFILE'

export const NAVIGATION_ITEMS = [
  {
    alt: 'My Profile',
    label: 'PROFILE',
    link: PROFILE,
  },
]

export const iconMap = {
  DASHBOARD: DashboardIcon,
  HELP: HelpIcon,
  NOTIFICATIONS: NotificationsIcon,
  PROFILE: ProfileIcon,
}
