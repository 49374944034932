import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, Hairline, HeaderTwo } from 'secondstep-components'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'

export const ChartsWrapper = styled.div`
  display: flex;
  height: 100%;

  ${themeGet('breakpoints.medium')} {
    flex-direction: column;
    align-items: center;
    padding: 0 1.25rem;

    [class*='ChartWrapper'] {
      &:not(:last-of-type) {
        margin-bottom: 3.1875rem;
      }
      &:last-of-type {
        margin-bottom: 0.625rem;
      }
    }
  }
`

export const HeaderWrapper = styled.div.attrs(({ isMultiSite }) => ({
  display: isMultiSite ? 'none' : 'flex',
}))`
  display: ${({ display }) => display};
  justify-content: space-between;
  margin-bottom: 1rem;
`

export const LeftBox = styled.div``

export const MainTitle = styled(HeaderTwo)`
  font-size: 1.4375rem;
`

export const MonitorSnapshotWrapper = styled.div.attrs(({ isMultiSite }) => ({
  padding: isMultiSite ? '0' : '1.375rem 1.25rem 1.25rem',
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${themeGet('colors.white')};
  border-radius: 0.625rem;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
  padding: ${({ padding }) => padding};
`

export const OverallProgressTitle = styled(HeaderTwo)`
  font-size: 1.25rem;
  margin-bottom: 0.875rem;
`

export const OverallProgressWrapper = styled.div.attrs(({ isMultiSite }) => ({
  padding: isMultiSite ? '1.375rem 1.125rem 2rem' : '1.375rem 1.125rem 2.5rem',
  paddingBottomMedium: isMultiSite ? '2.5rem' : '2',
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.625rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  padding: ${({ padding }) => padding};
  box-sizing: border-box;

  ${themeGet('breakpoints.medium')} {
    padding-bottom: ${({ paddingBottomMedium }) => paddingBottomMedium};
  }
`

export const StyledAdminExportDownloadButton = styled(
  AdminExportDownloadButton,
)`
  align-self: flex-end;
  margin-bottom: 0.3125rem;
`

export const StyledBodyCopy = styled(BodyCopy)``

export const StyledDonutHairline = styled(Hairline)`
  display: none;
  height: 2px;
  margin-bottom: 2.5rem;
  background-color: ${themeGet('colors.paleGray')};

  ${themeGet('breakpoints.medium')} {
    display: block;
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin-bottom: 2.5rem;
  background-color: ${themeGet('colors.paleGray')};
`
