import React from 'react'
import PropTypes from 'prop-types'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AdminDonutChart from 'components/AdminDonutChart'
import {
  ChartsWrapper,
  HeaderWrapper,
  LeftBox,
  MainTitle,
  MonitorSnapshotWrapper,
  OverallProgressTitle,
  OverallProgressWrapper,
  StyledAdminExportDownloadButton,
  StyledBodyCopy,
  StyledDonutHairline,
  StyledHairline,
} from './component.styles'
import {
  MAIN_DESCRIPTION_TEXT,
  MAIN_TITLE_TEXT,
  OVERALL_PROGRESS_TITLE_TEXT,
  PROGRAM_NAME_EDUCATOR_PRACTICES,
  PROGRAM_NAME_SCHOOLWIDE_PRACTICES,
  PROGRAM_NAME_STUDENT_ACTIVITIES,
} from './constants'

// remove mock data after resolver is created/connected
const mockSchoolwidePractices = {
  pathway1PracticesOrActivitiesCompleted: 2,
  pathway1PracticesOrActivitiesCount: 3,
  pathway2PracticesOrActivitiesCompleted: 1,
  pathway2PracticesOrActivitiesCount: 3,
  pathway3PracticesOrActivitiesCompleted: 0,
  pathway3PracticesOrActivitiesCount: 3,
  pathway4PracticesOrActivitiesCompleted: 0,
  pathway4PracticesOrActivitiesCount: 3,
  totalPracticesOrActivities: 12,
  totalPracticesOrActivitiesCompleted: 3,
  totalUsers: 2,
}

const mockEducatorPractices = {
  pathway1PracticesOrActivitiesCompleted: 60,
  pathway1PracticesOrActivitiesCount: 110,
  pathway2PracticesOrActivitiesCompleted: 40,
  pathway2PracticesOrActivitiesCount: 110,
  pathway3PracticesOrActivitiesCompleted: 32,
  pathway3PracticesOrActivitiesCount: 110,
  pathway4PracticesOrActivitiesCompleted: 0,
  pathway4PracticesOrActivitiesCount: 110,
  totalPracticesOrActivities: 440,
  totalPracticesOrActivitiesCompleted: 132,
  totalUsers: 55,
}

const mockStudentActivities = {
  grade10LessonsCompleted: 0,
  grade11LessonsCompleted: 66,
  grade12LessonsCompleted: 101,
  grade9LessonsCompleted: 54,
  totalPracticesOrActivitiesCompleted: 221,
  totalUsers: 36,
}

const AdminHsMonitorSnapshot = ({ isMultiSite }) => {
  return (
    <MonitorSnapshotWrapper isMultiSite={isMultiSite}>
      <HeaderWrapper isMultiSite={isMultiSite}>
        <LeftBox>
          <MainTitle>{MAIN_TITLE_TEXT}</MainTitle>
          <StyledBodyCopy>{MAIN_DESCRIPTION_TEXT}</StyledBodyCopy>
        </LeftBox>
        <StyledAdminExportDownloadButton
          programKey={TOOLTIP_PROGRAMS.HS}
          toggleView={TOGGLE_VIEW.MONITOR}
          tooltipLocation={TOOLTIP_LOCATIONS.EXPORT_BUTTON}
        />
      </HeaderWrapper>
      <OverallProgressWrapper isMultiSite={isMultiSite}>
        <OverallProgressTitle>
          {OVERALL_PROGRESS_TITLE_TEXT}
        </OverallProgressTitle>
        <StyledHairline />
        <ChartsWrapper>
          <AdminDonutChart
            data={mockSchoolwidePractices}
            programName={PROGRAM_NAME_SCHOOLWIDE_PRACTICES}
          />
          <StyledDonutHairline />
          <AdminDonutChart
            data={mockEducatorPractices}
            programName={PROGRAM_NAME_EDUCATOR_PRACTICES}
          />
          <StyledDonutHairline />
          <AdminDonutChart
            data={mockStudentActivities}
            programName={PROGRAM_NAME_STUDENT_ACTIVITIES}
          />
        </ChartsWrapper>
      </OverallProgressWrapper>
    </MonitorSnapshotWrapper>
  )
}

AdminHsMonitorSnapshot.propTypes = {
  isMultiSite: PropTypes.bool,
}

export default AdminHsMonitorSnapshot
