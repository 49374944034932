import React, { useState } from 'react'
import { func } from 'prop-types'
import KitActivationModal from 'components/KitActivationModal'
import { BackPackIcon, Column, Row, StyledButton } from './component.styles'

const FAMILY_ACCESS_ACTIVATION = 'Family Access'

const FamilyAccessLink = ({ navigationEvent }) => {
  const [showModalKit, setShowModalKit] = useState(false)
  const openModal = event => {
    if (event.type !== 'click' && event.key !== 'Enter') {
      return
    }
    setShowModalKit(true)
  }

  const closeModal = () => {
    if (showModalKit) setShowModalKit(false)
  }

  return (
    <Column>
      <Row>
        <StyledButton
          dataTestId="text-link-family-access-activation"
          onClick={event => openModal(event)}
          title={FAMILY_ACCESS_ACTIVATION}
        >
          <span>
            <BackPackIcon />
            {FAMILY_ACCESS_ACTIVATION}
          </span>
        </StyledButton>
      </Row>
      <KitActivationModal
        dataTestId="kit-activation-modal"
        isModalOpen={showModalKit}
        modalClose={closeModal}
        navigationEvent={navigationEvent}
      />
    </Column>
  )
}

FamilyAccessLink.propTypes = {
  navigationEvent: func,
}

export default FamilyAccessLink
