import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import { connect } from 'react-redux'

import withApiError, {
  ERROR_TYPE_LMS,
  ERROR_TYPE_PROFILE,
  SHOW_ERROR_IN_MODAL,
} from 'layers/errorHandling/apiError'
import { productMap } from 'utils/shapes'
import { operations as productOperations } from 'store/productManager'

import { Loading, ErrorMessage, NotFound } from 'secondstep-components'
import PagesContainer from 'containers/Pages'
import Sela from 'pages/Sela'
import Training from 'pages/Training'
import Lessons from 'pages/Lessons'
import LessonsV2 from 'pages/LessonsV2'
import HighSchoolTrainingLanding from 'pages/HighSchoolTrainingLanding'
import HsIndividualResource from 'pages/HsIndividualResource'
import ResourcesLanding from 'pages/ResourcesLanding'
// TODO: fix import when the High School + Middle School flags are removed:
import ResourceContent from 'pages/ResourcesContent/flag.on'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import { isFlagOn } from 'utils/featureFlags'

import { getStaticRoutes } from './data'
import { ProductWrapper } from './component.styles'
import { HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH } from 'utils/highschoolHelpers'

export class ProductContainer extends Component {
  static propTypes = {
    getProductMap: PropTypes.func.isRequired,
    lmsError: PropTypes.object,
    match: PropTypes.object.isRequired,
    productMap,
    resetContentNodes: PropTypes.func,
    theme: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      errorStatus: null,
      errorDetails: null,
    }
  }

  componentDidMount() {
    const { getProductMap, match } = this.props
    const map = getProductMap(match.params.productName)

    if (!map) {
      const error = {
        errorStatus: 404,
        errorDetails:
          'Requested program was not found. Please check the url and try again.',
      }
      this.setState(error)
    }
  }

  render() {
    const { props, state } = this
    const { errorStatus, errorDetails } = state || {}
    const { match, productMap } = props
    const { path } = match || {}

    if (errorStatus) {
      return (
        <ErrorMessage
          additionalDetails={errorDetails}
          statusCode={errorStatus}
        />
      )
    }

    if (!productMap) {
      return <Loading loadingText="Loading program data..." />
    }

    const isSela =
      match?.params.productName === 'sel-for-adults' ||
      'sel-for-adults-field-test'

    return (
      <LearnErrorBoundary>
        <ProductWrapper isSelaPath={isSela}>
          <Switch>
            <Route
              component={HsIndividualResource}
              path={`${HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH}/:slug`}
            />
            {/* this route is only available in Program Flex: */}
            {isFlagOn(['high-school-june-release']) && (
              <Route
                component={HighSchoolTrainingLanding}
                // only match the high school landing training page, not its children:
                exact
                path="/product/high-school/training"
              />
            )}
            <Route
              component={ResourceContent}
              path="/product/high-school/pathways/:pathway_name/overview"
            />
            <Route
              component={ResourcesLanding}
              exact
              path="/product/high-school/program-supports"
            />
            <Route component={PagesContainer} path={getStaticRoutes(path)} />
            <Route component={Sela} path={`${path}/modules`} />
            <Route
              component={LessonsV2}
              path={`/product/middle-school/lessons`}
            />
            <Route component={Lessons} path={`${path}/lessons`} />
            <Route component={Training} path={`${path}/training`} />
            <Redirect
              exact
              from={path}
              to={`${path}/${productMap.defaultPage}`}
            />
            <Route component={NotFound} />
          </Switch>
        </ProductWrapper>
      </LearnErrorBoundary>
    )
  }
}

const mapStateToProps = ({ productManager }) => {
  return {
    productMap: productManager.productMap,
  }
}

const mapDispatchToProps = {
  getProductMap: productOperations.getProductMap,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withApiError(
    ProductContainer,
    [ERROR_TYPE_PROFILE, ERROR_TYPE_LMS],
    [SHOW_ERROR_IN_MODAL],
  ),
)
