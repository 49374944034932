/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'

import LandingBanner from 'experienceComponents/LandingBanner'
import FeatureCategoryCallout from 'experienceComponents/FeatureCategoryCallout'
import CategoryCalloutDisplay from 'experienceComponents/CategoryCalloutDisplay'
import {
  isPreviewMode,
  isHighSchoolProgramSupports,
} from 'utils/highschoolHelpers'

import {
  BackgroundWrapper,
  ContentWrapper,
  ResourcesLandingWrapper,
} from './component.styles'

import iconMap from 'utils/iconMap'

const CATEGORIES_TEXT = 'All Categories'

const ResourcesLanding = props => {
  const { navigationEvent, location, isDistrict, programSectionArticleTree } =
    props || {}
  const isPreview = isPreviewMode()
  const { programSubsections, description, displayTitle } =
    programSectionArticleTree || {}
  const [featureCategoryCalloutData = {}, ...categoryCalloutsData] =
    programSubsections || []
  const { image: featuredImage, pageData } = featureCategoryCalloutData || {}

  const { image } = featuredImage || {}
  const { url } = image || {}
  const { route } = pageData || {}
  const isHsSupports = isHighSchoolProgramSupports()

  const categoriesMapper = categoryCalloutData => {
    const {
      description,
      displayTitle,
      iconSelector = isHsSupports ? 'hsTrees' : 'rocket',
      pageData = {},
    } = categoryCalloutData
    const { route } = pageData

    return {
      Icon: iconMap[iconSelector],
      goToLocation: navigationEvent,
      location,
      text: description,
      title: displayTitle,
      link: route,
    }
  }

  const navigateToDashboardOnClick = ({ navigationEvent }) => {
    const url = `/`
    const type = NavigationHelper.types.PUSH
    navigationEvent(url, type)
  }

  return (
    <ResourcesLandingWrapper>
      <LandingBanner
        isDistrict={isDistrict}
        isHsSupports={isHsSupports}
        isPreview={isPreview}
        navigateToDashboardOnClick={navigateToDashboardOnClick}
        text={description}
        title={displayTitle}
      />
      <BackgroundWrapper>
        <ContentWrapper>
          <FeatureCategoryCallout
            body={featureCategoryCalloutData.description}
            id={featureCategoryCalloutData.id}
            image={url}
            link={route}
            location={location}
            navigationEvent={navigationEvent}
            title={featureCategoryCalloutData.displayTitle}
          />
          {categoryCalloutsData.length >= 1 && (
            <CategoryCalloutDisplay
              categoryInfo={categoryCalloutsData.map(categoriesMapper)}
              dataTestId="category-callout"
              displayHeaderText={CATEGORIES_TEXT}
              navigationEvent={navigationEvent}
            />
          )}
        </ContentWrapper>
      </BackgroundWrapper>
    </ResourcesLandingWrapper>
  )
}

ResourcesLanding.propTypes = {
  isDistrict: PropTypes.bool,
  isPreview: PropTypes.bool,
  location: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  programSectionArticleTree: PropTypes.object,
}

export default ResourcesLanding
