import { View } from 'react-native'
import { Text } from 'grommet'
import styled from 'styled-components'
import styledNative, { withTheme } from 'styled-components/native'
import { themeGet } from 'styled-system'
import { TabButton } from 'secondstep-components'

export const Container = styled.div.attrs(props => ({
  borderWidth: `${themeGet('globalNav.borderWidth')(props)}px`,
  borderColor: themeGet(
    'globalNav.borderColor',
    'globalNav.background',
    'colors.orange',
  )(props),
}))`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  min-height: 50px;
  position: relative;
  z-index: 5;
  background-color: ${themeGet('globalNav.background', 'colors.orange')};
  border-bottom-width: ${({ borderWidth }) => borderWidth};
  border-bottom-style: solid;
  border-bottom-color: ${({ borderColor }) => borderColor};
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const SkipToMainWrapper = styled.div.attrs(props => {
  const background = themeGet(
    'skipToMain.background',
    'colors.opacity_20',
  )(props)
  const color = themeGet('skipToMain.color', 'colors.white')(props)
  const hoverColor = themeGet('skipToMain.hoverColor', 'colors.white')(props)

  return {
    background,
    color,
    hoverColor,
  }
})`
  width: 8.3125rem;
  height: 1.6875rem;
  margin-right: 1rem;
  padding-top: 0.375rem;
  background-color: ${({ background }) => background};
  border-radius: 0.3125rem;
  align-self: center;
  text-align: center;
  position: relative;
  transform: translateY(-300%);
  transition: transform 0.3s;
  order: 2

  :focus-within {
    transform: translateY(0%);
    outline: -webkit-focus-ring-color auto 2px;
    outline: 2px auto Highlight;
  }

  a {
    color: ${({ color }) => color};
    font-family: ${themeGet('font.molde.medium')};
    text-decoration: none;

    :focus {
      outline: none;
    }

    :hover {
      color: ${({ hoverColor }) => hoverColor};
      text-decoration: underline;
    }
  }

  @media (max-width: 730px) {
    display: none;
  }
`

export const Left = styledNative(View)(() => ({
  flexDirection: 'row',
  order: 1,
}))

export const LogoContainer = styled.a`
  color: ${themeGet('globalNav.primaryIconColor', 'colors.white')};
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 5;
  padding-top: 0.875rem;

  :focus,
  :hover {
    background-color: ${themeGet(
      'globalNav.iconHoverBackground',
      'rgba(255, 255, 255, 0.2)',
    )};
    cursor: pointer;
    border-bottom-color: ${themeGet(
      'globalNav.borderColor',
      'globalNav.background',
      'colors.orange',
    )};
    padding-bottom: 0;
  }
`

export const Divider = styled.div`
  border-right: 1.5px solid ${themeGet('globalNav.dividerColor', '#d8d8d8')};
  opacity: ${themeGet('globalNav.dividerOpacity', '0.2')};
`

export const TitleContainer = styled.div.attrs(
  ({ isAdminDashboard, isEducatorDashboard }) => ({
    adminDisplay: isAdminDashboard || isEducatorDashboard ? 'none' : 'flex',
  }),
)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.25rem;

  @media (max-width: 730px) {
    display: ${({ adminDisplay }) => adminDisplay};
  }

  @media (max-width: 376px) {
    max-width: 250px;
    padding: 1rem 0;
  }
`

export const Title = styled(Text).attrs(props => {
  const borderExists = themeGet('globalNav.borderColor')(props)
  const margin = borderExists ? '0.25rem 0 0' : '0'
  const color = themeGet('globalNav.titleColor', 'colors.white')(props)
  const fontFamily = themeGet('globalNav.fontFamily', 'SharpSlab-Bold')(props)

  return {
    margin,
    color,
    fontFamily,
  }
})`
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 18px;
`

export const Right = styledNative(View)(() => ({
  alignSelf: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  marginRight: 10,
  order: 3,
}))

export const Icons = styledNative(View)(() => ({
  flexDirection: 'row',
}))

export const IconContainer = withTheme(
  styledNative(View)(props => {
    const { hover } = props
    const defaultStyles = {
      alignItems: 'center',
      borderRadius: 5,
      height: 30,
      justifyContent: 'center',
      marginRight: 5,
      width: 30,
      color: themeGet('globalNav.secondaryIconColor', 'colors.white')(props),
      zIndex: 5,
    }

    if (hover) {
      return {
        ...defaultStyles,
        backgroundColor: themeGet(
          'globalNav.iconHoverBackground',
          'rgba(255, 255, 255, 0.2)',
        )(props),
        cursor: 'pointer',
      }
    } else {
      return defaultStyles
    }
  }),
)

export const Badge = styledNative(Text)(
  ({ borderColor, backgroundColor = '#F3544C' }) => ({
    backgroundColor,
    borderColor,
    borderRadius: 17 / 2,
    borderWidth: 2,
    color: 'white',
    fontFamily: 'Molde-Medium',
    fontSize: 11,
    height: 17,
    position: 'absolute',
    right: -4,
    textAlign: 'center',
    top: 0,
    width: 17,
  }),
)

export const TabSetWrapper = styled.div`
  margin-top: 0.5rem;
  margin-right: 0.875rem;
`

export const StyledTabButton = styled(TabButton).attrs(({ isActive }) => ({
  backgroundColor: isActive ? themeGet('colors.lightOrange') : 'transparent',
  fontFamily: isActive
    ? themeGet('fontFamilySemibold')
    : themeGet('fontFamily'),
}))`
  [class*='StyledBox'] {
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 1rem;
  }

  [class*='Underline'] {
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: relative;
    top: 13px;
  }

  ${themeGet('breakpoints.mobile')} {
    &:first-of-type {
      margin-right: 1rem;
    }
  }
`
