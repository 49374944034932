import { PROGRAM_PERMISSION_MAPPING } from './adminExperienceHelpers'

export const TOOLTIP_PROGRAMS = {
  BPU: 'BPU',
  CPU: 'CPU',
  HS: 'HS',
  K8: 'K8',
  K8_MONITOR: 'K8_MONITOR',
  SELA: 'SELA',
}

export const TOOLTIP_LOCATIONS = {
  EXPORT_BUTTON: 'export button',
  HEADER_LESSONS: 'Monitor: Completion Snapshot head - lessons completed',
  HS_ADMIN_USER_MANAGEMENT_TILE: 'User Management tile',
  HS_ADMIN_SCHOOL_SETUP_HEADER: 'School Setup Required header',
  HS_ADMIN_SET_UP_START_DATE_DEACTIVATED:
    'Set a Start Date (deactivated state)',
  LAUNCH_CLASSES: 'Classes: Sites table',
  LAUNCH_CLASSES_CREATED: 'Site Detail: Classes Created',
  LAUNCH_SITE: 'Site: Sites table',
  LAUNCH_TRAINING_COMPLETION: 'Sites Table: Program Training Completion',
  LAUNCH_USERS: 'Sites Table: Users',
  LAUNCH_USERS_ADDED: 'Launch Snapshot: Users Added',
  LAUNCH_USERS_WITH_CLASSES: 'Users WithClasses: Site details',
  SITE_LESSONS:
    'Monitor: Completion Snapshot, single school - # Lessons Completed Schoolwide head',
  SITE_UNIT_1:
    'Monitor: Completion Snapshot, single school - # Lessons Completed U1 head',
  SITE_UNIT_2:
    'Monitor: Completion Snapshot, single school - # Lessons Completed U2 head',
  SITE_UNIT_3:
    'Monitor: Completion Snapshot, single school - # Lessons Completed U3 head',
  SITE_UNIT_4:
    'Monitor: Completion Snapshot, single school - # Lessons Completed U4 head',
  SITES_TABLE_EDUCATOR_PRACTICES: 'Sites Table: Educator Practices',
  SITES_TABLE_PACING: 'Sites Table: Pacing',
  SITES_TABLE_SCHOOLWIDE_PRACTICES: 'Sites Table: Student Activities',
  SITES_TABLE_STUDENT_ACTIVITIES: 'Sites Table: Student Activities',
  TABLE_CLASSES: 'Monitor: Completion Snapshot table - Classes Started column',
  TABLE_LESSONS:
    'Monitor: Completion Snapshot table - Lesson Completion column',
  TABLE_SCHOOLS: 'Monitor: Completion Snapshot table - School column',
  TABLE_UNIT_1: 'Monitor: Completion Snapshot table - Unit 1 column',
  TABLE_UNIT_2: 'Monitor: Completion Snapshot table - Unit 2 column',
  TABLE_UNIT_3: 'Monitor: Completion Snapshot table - Unit 3 column',
  TABLE_UNIT_4: 'Monitor: Completion Snapshot table - Unit 4 column',
}

export const findTooltipByLocation = (
  location,
  programKey,
  snapshots,
  panelName = null,
) => {
  if (!location || !programKey || !snapshots) {
    return
  }
  const programPermissionKey = PROGRAM_PERMISSION_MAPPING[programKey]
  const snapshot =
    snapshots.find(snap => snap?.permissionsPrograms?.[programPermissionKey]) ||
    {}
  let tooltips = []
  if (panelName) {
    const panel = snapshot[panelName] || {}
    tooltips = panel.tooltips || []
  } else {
    tooltips = snapshot.tooltips || []
  }
  const match = tooltips?.find(t => t.tooltipLocation === location)

  return match
}
