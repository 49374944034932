import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.DOCUMENT_SERVICE}`

const api = (function(baseApiAddress) {
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 60000,
  })

  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  _api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        location.replace(window.location.origin)
      }
      return Promise.reject(error)
    },
  )

  return {
    fetchDocumentUrl: async ({
      userEmail,
      siteId,
      program,
      refresh,
      options,
    }) => {
      const token = await Auth0Manager.getAccessToken()
      const response = await _api.post(`/api/SiteReport`, {
        userEmail,
        siteId,
        auth: token,
        program,
        refresh,
        options,
      })

      return { url: response.data.sasUrl }
    },
  }
})(baseApiAddress, true)

export default api
