import { createSlice } from '@reduxjs/toolkit'
import { TOGGLE_VIEW } from './constants'

export const initialState = {
  adminDashboard: {
    checklist: {
      hsSelectedTab: null,
      items: {
        aboutProgram: false,
        classCreation: false,
        completePlaybook: false,
        elemProgKickoff: false,
        endYearPpt: false,
        inviteAll: false,
        inviteLeaders: false,
        midYearPpt: false,
        monitorCompletion: false,
      },
      msSelectedTab: null,
    },
    hs: {
      toggleView: TOGGLE_VIEW.LAUNCH,
    },
    k8: {
      isWelcomeMessageOpen: true,
      toggleView: TOGGLE_VIEW.LAUNCH,
    },
    lessons: {
      lessonSelectedRow: {
        rowId: null,
        selectedUnitTab: null,
      },
    },
    recentLeaderDashboardUrl: null,
  },
  programDashboard: {
    recentDashboardTab: null,
  },
}

const reducers = {
  adminSetIsWelcomeMessageOpen: (state, { payload }) => {
    state.adminDashboard.k8.isWelcomeMessageOpen = payload.isOpen
  },
  adminSetLessonSelectedRow: (state, { payload }) => {
    state.adminDashboard.lessons.lessonSelectedRow.rowId = payload.rowId
    state.adminDashboard.lessons.lessonSelectedRow.selectedUnitTab =
      payload.selectedUnitTab
  },
  adminSetHsSelectedChecklistTab: (state, { payload }) => {
    state.adminDashboard.checklist.hsSelectedTab = payload.selectedTab
  },
  adminSetMsSelectedChecklistTab: (state, { payload }) => {
    state.adminDashboard.checklist.msSelectedTab = payload.selectedTab
  },
  adminSetHSToggleView: (state, { payload }) => {
    state.adminDashboard.hs.toggleView = payload.toggleView
  },
  adminSetK8ToggleView: (state, { payload }) => {
    state.adminDashboard.k8.toggleView = payload.toggleView
  },
  adminToggleChecklistItem: (state, { payload }) => {
    state.adminDashboard.checklist.items[payload.internalKey] =
      payload.isChecked
  },
  programDashboardSetRecentDashboardTab: (state, { payload }) => {
    state.programDashboard.recentDashboardTab = payload.recentDashboardTab
  },
  adminDashboardSetRecentUrl: (state, { payload }) => {
    state.adminDashboard.recentLeaderDashboardUrl =
      payload.recentLeaderDashboardUrl
  },
}

const slice = createSlice({
  initialState,
  name: 'userSessionManager',
  reducers,
})

export const actions = slice.actions
export default slice.reducer
