import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TooltipContentWrapper } from 'secondstep-components'
import AdminSearchSelect from 'components/AdminSearchSelect'
import AdminTooltip from 'components/AdminTooltip'

export const ExportButtonWrapper = styled.div`
  display: flex;
`

export const StyledAdminSearchSelect = styled(AdminSearchSelect).attrs(
  props => ({
    background: props.isFetching
      ? themeGet('colors.gray1200')(props)
      : themeGet('colors.blue')(props),
    buttonCursor: props.disabled ? 'auto' : 'pointer',
    buttonPadding: props.isFetching
      ? '0.3125rem 0.375rem 0.1875rem'
      : '0.3125rem 0.6875rem 0.1875rem',
    buttonPointerEvents: props.disabled ? 'none' : 'auto',
    iconMargin: props.isFetching ? '0' : '0 0 0.125rem',
    labelPadding: props.isFetching
      ? '0 0.5rem 0 0.125rem'
      : '0 0.625rem 0 0.5rem',
    svgDisplay: props.isFetching ? 'none' : 'block',
  }),
)`
  min-width: 165px;
  width: auto;
  padding: 0;

  svg {
    &:first-of-type {
      width: 26px;
      height: 18px;
      transform: none;
      margin: ${({ iconMargin }) => iconMargin};
    }

    &:last-of-type {
      display: ${({ svgDisplay }) => svgDisplay};
      width: 14px;
      height: 12px;
      margin-left: auto;
    }
  }

  [class*='DropdownButton'] {
    padding: ${({ buttonPadding }) => buttonPadding};
    border: none;
    background: ${({ background }) => background};
    transition: background 0.2s ease;
    cursor: ${({ buttonCursor }) => buttonCursor};
    pointer-events: ${({ buttonPointerEvents }) => buttonPointerEvents};

    span {
      padding: ${({ labelPadding }) => labelPadding};
    }

    &:hover {
      border-bottom: 0;
      border-radius: 15px;
      background: ${themeGet('colors.darkBlue')};
      transition: background 0.2s ease;
    }
  }

  [class*='DropdownOptions'] {
    margin-top: 0.5rem;

    button {
      padding: 0 1rem 0 0.5625rem;
    }

    span {
      padding-left: 0;
    }

    svg {
      display: none;
    }
  }
`

export const StyledAdminTooltip = styled(AdminTooltip)`
  ${TooltipContentWrapper} {
    top: 34px;
    left: -205px;
    transform: none;

    :: after {
      top: -54px;
      right: 9px;
      left: unset;
    }
  }
`
