import { actions } from './reducer'
import api from '../../lib/api/reportsApi'
import { formatError } from 'utils/formatError'
import {
  mockK8ReportClassDetailsData,
  mockK8ReportGradeAndClassDetailsData,
  mockK8ReportGradeData,
  mockK8ReportSchoolData,
} from './test/__mocks__/k8Report'

const clearErrors = () => {
  return async dispatch => {
    dispatch(actions.clearErrors())
  }
}

const fetchHsLaunchSnapshot = adminEmail => {
  return async dispatch => {
    dispatch(actions.fetchingHsLaunchSnapshot())
    try {
      const { data } = await api.getAdminHsLaunchSnapshot(adminEmail)
      validateResponse(data)
      dispatch(actions.fetchHsLaunchSnapshotSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchHsLaunchSnapshotError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchLessonCompletionData = ({ adminEmail, siteId, program }) => {
  return async dispatch => {
    dispatch(actions.fetchingLessonCompletionData(adminEmail, siteId, program))
    try {
      const { data } = await api.getLessonCompletionData(
        adminEmail,
        siteId,
        program,
      )
      validateResponse(data)
      dispatch(actions.fetchLessonCompletionDataSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchLessonCompletionDataError({
          error: formatError(error),
        }),
      )
    }
  }
}

const validateResponse = data => {
  const received200ButErrorObjectExists = data.errors && data.errors.length > 0

  if (received200ButErrorObjectExists) {
    const message = data.errors[0]?.message
    throw new Error(message)
  }
}

const deleteReportsData = () => {
  return async dispatch => {
    dispatch(actions.deletingReportsData())
  }
}

const fetchK8ReportSchool = licenseId => {
  const isLocalEnv = process.env.BUILD_TYPE === 'local'
  return async dispatch => {
    dispatch(actions.fetchingReportsSchoolData())
    try {
      const { data } = isLocalEnv
        ? mockK8ReportSchoolData(licenseId)
        : await api.getK8ReportSchool(licenseId)
      validateResponse(data)
      dispatch(actions.fetchK8ReportSchoolSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchingReportsSchoolDataError({ error: formatError(error) }),
      )
    }
  }
}

const fetchK8ReportSchoolBySiteId = siteId => {
  return async dispatch => {
    dispatch(actions.fetchingReportsSchoolData())
    try {
      const { data } = await api.getK8ReportSchoolBySiteId(siteId)
      validateResponse(data)
      dispatch(actions.fetchK8ReportSchoolSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchingReportsSchoolDataError({ error: formatError(error) }),
      )
    }
  }
}

const fetchAdminK8Sites = (pageSize, pageNumber, orderBy, sortKey) => {
  return async dispatch => {
    dispatch(actions.fetchingAdminK8SitesList())

    try {
      const { data } = await api.getAdminK8SitesPaginated(
        pageSize,
        pageNumber,
        orderBy,
        sortKey,
      )
      validateResponse(data)

      const payload = data

      dispatch(actions.fetchAdminK8SitesSuccess(payload))
    } catch (error) {
      dispatch(
        actions.fetchingAdminK8SitesListError({ error: formatError(error) }),
      )
    }
  }
}

const fetchAdminK8SitesSummary = () => {
  return async dispatch => {
    dispatch(actions.fetchingAdminK8SitesSummary())

    try {
      const { data } = await api.getAdminK8SitesSummary()

      validateResponse(data)
      dispatch(actions.fetchAdminK8SitesSummarySuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchingAdminK8SitesSummaryError({ error: formatError(error) }),
      )
    }
  }
}

const fetchAdminSelaSitesList = () => {
  return async dispatch => {
    dispatch(actions.fetchingAdminSelaSitesList())

    try {
      const { data } = await api.getAdminSelaSitesList()
      validateResponse(data)

      const payload = data

      dispatch(actions.fetchAdminSelaSitesListSuccess(payload))
    } catch (error) {
      dispatch(
        actions.fetchingAdminSelaSitesListError({ error: formatError(error) }),
      )
    }
  }
}

const fetchK8ReportGrade = (licenseId, gradeId) => {
  const isLocalEnv = process.env.BUILD_TYPE === 'local'
  return async dispatch => {
    dispatch(actions.fetchingReportsGradeData())
    try {
      const { data } = isLocalEnv
        ? mockK8ReportGradeData(licenseId, gradeId)
        : await api.getK8ReportGrade(licenseId, gradeId)
      validateResponse(data)
      dispatch(actions.fetchK8ReportGradeSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchK8ReportGradeError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchK8ReportGradeBySiteId = (siteId, gradeId) => {
  return async dispatch => {
    dispatch(actions.fetchingReportsGradeData())
    try {
      const { data } = await api.getK8ReportGradeBySiteId(siteId, gradeId)
      validateResponse(data)
      dispatch(actions.fetchK8ReportGradeSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchK8ReportGradeError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchK8ReportGradeAndClassDetails = (
  licenseId,
  gradeId,
  classInstance,
) => {
  const isLocalEnv = process.env.BUILD_TYPE === 'local'
  return async dispatch => {
    dispatch(actions.fetchingReportsGradeAndClassData())
    try {
      const { data } = isLocalEnv
        ? mockK8ReportGradeAndClassDetailsData(
            licenseId,
            gradeId,
            classInstance,
          )
        : await api.getK8ReportGradeAndClassDetails(
            licenseId,
            gradeId,
            classInstance,
          )
      validateResponse(data)
      dispatch(actions.fetchK8ReportGradeAndClassDetailsSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchK8ReportGradeAndClassDetailsError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchK8ReportGradeAndClassDetailsBySiteId = (
  siteId,
  gradeId,
  classInstance,
) => {
  return async dispatch => {
    dispatch(actions.fetchingReportsGradeAndClassData())
    try {
      const { data } = await api.getK8ReportGradeAndClassDetailsBySiteId(
        siteId,
        gradeId,
        classInstance,
      )
      validateResponse(data)
      dispatch(actions.fetchK8ReportGradeAndClassDetailsSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchK8ReportGradeAndClassDetailsError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchK8ReportClassDetails = (licenseId, classInstance) => {
  const isLocalEnv = process.env.BUILD_TYPE === 'local'
  const mockGradeName = 'Grade 1' // TODO: marked done dates wont match brightspot count for !grade-1

  return async dispatch => {
    dispatch(actions.fetchingReportsClassData())
    try {
      const { data } = isLocalEnv
        ? mockK8ReportClassDetailsData(licenseId, mockGradeName, classInstance)
        : await api.getK8ReportClassDetails(licenseId, classInstance)

      validateResponse(data)
      dispatch(actions.fetchK8ReportClassDetailsSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchingK8ReportClassDetailsError({
          error: formatError(error, false, 'k8-class-details'),
        }),
      )
    }
  }
}

const fetchK8ReportClassDetailsBySiteId = (siteId, classInstance) => {
  return async dispatch => {
    dispatch(actions.fetchingReportsClassData())
    try {
      const { data } = await api.getK8ReportClassDetailsBySiteId(
        siteId,
        classInstance,
      )

      validateResponse(data)
      dispatch(actions.fetchK8ReportClassDetailsSuccess(data))
    } catch (error) {
      dispatch(
        actions.fetchingK8ReportClassDetailsError({
          error: formatError(error, false, 'k8-class-details'),
        }),
      )
    }
  }
}

const fetchReportsContentTrack = licenseId => {
  return async dispatch => {
    dispatch(actions.fetchingReportsData())
    try {
      const { data } = await api.getReportsContentTrack(licenseId)
      validateResponse(data)
      dispatch(actions.fetchingReportsContentTrackSuccess(data))
    } catch (error) {
      dispatch(actions.fetchingReportsDataError({ error: formatError(error) }))
    }
  }
}

const fetchReportsUserDetails = licenseId => {
  return async (dispatch, store) => {
    const userDetailsExist =
      store?.reportsManager?.reports?.[licenseId]?.user_details
    dispatch(actions.fetchingReportsData())

    try {
      if (!userDetailsExist) {
        const { data } = await api.getReportsUsersDetail(licenseId)
        validateResponse(data)
        dispatch(actions.fetchingReportsUsersDetailSuccess(data))
      }
    } catch (error) {
      dispatch(actions.fetchingReportsDataError({ error: formatError(error) }))
    }
  }
}

const updateSelectedContentTrack = contentTrack => {
  return dispatch => {
    dispatch(actions.updateSelectedContentTrack({ contentTrack }))
  }
}

const updateSelectedGrade = gradeName => {
  return dispatch => {
    dispatch(actions.updateSelectedGrade({ gradeName }))
  }
}

const updateSelectedLicense = licenseId => {
  return dispatch => {
    dispatch(actions.updateSelectedLicense({ licenseId }))
  }
}

const updateSelectedModuleId = moduleContentfulId => {
  return dispatch => {
    dispatch(actions.updateSelectedModuleId({ moduleContentfulId }))
  }
}
const updateLaunchSelectedPaginationSortKey = sortKey => {
  return dispatch => {
    dispatch(actions.updateLaunchSelectedPaginationSortKey({ sortKey }))
  }
}
const updateLaunchSelectedPaginationSortOrder = orderBy => {
  return dispatch => {
    dispatch(actions.updateLaunchSelectedPaginationSortOrder({ orderBy }))
  }
}
const updateMonitorSelectedPaginationCurrentPage = page => {
  return dispatch => {
    dispatch(actions.updateMonitorSelectedPaginationCurrentPage({ page }))
  }
}
const updateMonitorSelectedPaginationSortKey = sortKey => {
  return dispatch => {
    dispatch(actions.updateMonitorSelectedPaginationSortKey({ sortKey }))
  }
}
const updateMonitorSelectedPaginationSortOrder = orderBy => {
  return dispatch => {
    dispatch(actions.updateMonitorSelectedPaginationSortOrder({ orderBy }))
  }
}
const updateSelectedSite = site => {
  return dispatch => {
    dispatch(actions.updateSelectedSite({ site }))
  }
}
const updateProgramTitles = programTitles => {
  return dispatch => {
    dispatch(actions.updateProgramTitles({ programTitles }))
  }
}
const updateProgramsForUser = programs => {
  return dispatch => {
    dispatch(actions.updateProgramsForUser({ programs }))
  }
}
const updateSelectedProgram = program => {
  return dispatch => {
    dispatch(actions.updateSelectedProgram({ program }))
  }
}

export default {
  fetchHsLaunchSnapshot,
  fetchLessonCompletionData,
  clearErrors,
  deleteReportsData,
  fetchReportsContentTrack,
  fetchReportsUserDetails,
  fetchK8ReportSchool,
  fetchK8ReportSchoolBySiteId,
  fetchK8ReportGrade,
  fetchK8ReportGradeBySiteId,
  fetchK8ReportGradeAndClassDetails,
  fetchK8ReportGradeAndClassDetailsBySiteId,
  fetchK8ReportClassDetails,
  fetchK8ReportClassDetailsBySiteId,
  fetchAdminK8Sites,
  fetchAdminK8SitesSummary,
  fetchAdminSelaSitesList,
  updateProgramTitles,
  updateProgramsForUser,
  updateSelectedContentTrack,
  updateSelectedGrade,
  updateSelectedLicense,
  updateSelectedModuleId,
  updateLaunchSelectedPaginationSortKey,
  updateLaunchSelectedPaginationSortOrder,
  updateMonitorSelectedPaginationCurrentPage,
  updateMonitorSelectedPaginationSortKey,
  updateMonitorSelectedPaginationSortOrder,
  updateSelectedProgram,
  updateSelectedSite,
}
