import React from 'react'
import PropTypes from 'prop-types'

import { CloseButton } from 'secondstep-components'

import { Header2, StyledModal } from './component.styles'

const ClosableModal = ({
  height,
  id,
  onClose,
  className,
  children,
  show,
  showClose,
  width,
  modalTitle,
  overlayBgColor,
}) => {
  const dataTestIdPrefix = `closable-modal`
  const dataTestIdCloseButton = id
    ? `${dataTestIdPrefix}-close-button-${id}`
    : `${dataTestIdPrefix}-close-button`
  const dataTestIdModal = id
    ? `${dataTestIdPrefix}-${id}`
    : `${dataTestIdPrefix}`
  return (
    <StyledModal
      className={className}
      contentLabel={modalTitle}
      dataTestId={dataTestIdModal}
      height={height}
      isModalOpen={show}
      modalClose={onClose}
      overlayBgColor={overlayBgColor}
      width={width}
    >
      {modalTitle && <Header2>{modalTitle}</Header2>}
      {showClose && (
        <CloseButton dataTestId={dataTestIdCloseButton} onClick={onClose} />
      )}
      {children}
    </StyledModal>
  )
}

ClosableModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  height: PropTypes.string,
  id: PropTypes.string,
  modalTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  overlayBgColor: PropTypes.string,
  show: PropTypes.bool.isRequired,
  showClose: PropTypes.bool,
  width: PropTypes.string,
}

ClosableModal.defaultProps = {
  height: '100%',
  width: '100%',
  showClose: true,
  modalTitle: '',
}

export default ClosableModal
