export const MIND_YETI_ROUTES = {
  BASE_URL: '/product/mind-yeti',
  RESOURCES: {
    DEFAULT: '/',
    PLAYLIST: '/playlists',
  },
}
export function getStaticRoutes(path) {
  return [
    `${path}/program-supports`,
    `${path}/program-supports-k5`,
    `${path}/program-supports-el`,
    `${path}/resources`,
    `${path}/principal-toolkit`,
    `${path}/trainings`,
    `${path}/families`,
    `${path}/staff-training`,
    `${path}/program-training`,
    `${path}/advisory-activities`,
    `${path}/activities`,
    `${path}/learning-resources`,
    // the /admin route below is temporary and meant to sunset /sel-for-adults/leader-hub.
    // angie (or product) will let us know when they make a final decision on whether it will
    // supersede /leader-hub or be a new route entirely
    `${path}/admin`,
    `${path}/leader-hub`,
    `${path}/engage-families`,
    `${path}/accessibility`,
    `/product/k5-kit/teach`,
    `/product/bpu-kit/teach`,
    `/product/cpu-kit/teach`,
    `/product/el-kit/teach`,
    `/product/ms-kit/ms-teach`,
    `${MIND_YETI_ROUTES.BASE_URL}${MIND_YETI_ROUTES.RESOURCES.PLAYLIST}`,
    `/product/high-school/library`,
    `/product/high-school/training`,
    `/product/high-school/pathways`,
    `${path}/grades-k-1`,
    `${path}/grades-2-3`,
    `${path}/grades-4-5`,
  ]
}
