import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { GetRootContent } from 'layers/content/Hocs/RootContent'
import NavigationHelper from 'layers/navigation/navigationHelper'

import { navContentMapper } from './data'
import Component from './component'

function NavigationContainer(props) {
  useEffect(() => {
    const { requestRemount } = props
    // need to remount to get the flag.on version
    // if the next page uses the new subnav system
    // NOTE: this should be removed when we remove the feature flag on this:
    if (!!requestRemount && typeof requestRemount === 'function') {
      requestRemount()
    }
  }, [window.location.pathname])

  return <Component {...props} />
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)

const userConnect = connect(state => {
  const { userAccessManager } = state || {}
  const { claims } = userAccessManager || {}
  const { userProfileManager } = state || {}
  const { profile } = userProfileManager || {}
  const { firstName = '', lastName = '', roles = [] } = profile || {}
  return {
    roles,
    userClaims: claims,
    name: `${firstName} ${lastName}`.trim(),
  }
})

const options = {
  include: 3,
  mapper: navContentMapper,
  spread: true,
}

NavigationContainer.propTypes = {
  requestRemount: PropTypes.func,
}

export default GetRootContent(
  NavigationContainer,
  options,
  navigationConnect,
  userConnect,
)
