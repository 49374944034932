import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'
import SubNavGetter, {
  SubNavGetterComponentPropTypes,
} from 'layers/content/v2/Hocs/SubNavGetter'
import {
  getThemeFromUrl,
  getProductFromUrl,
  resolveRouteServiceApp,
} from 'utils/productHelpers'

import Component from './component'

function NavigationContainer(props) {
  useEffect(() => {
    const { requestRemount } = props
    // need to remount to get the flag.off version if this is not a route
    // that should use the new nav system
    // NOTE: this should be removed when we remove the feature flag on this:
    if (!!requestRemount && typeof requestRemount === 'function') {
      requestRemount()
    }
  }, [window.location.pathname])

  const theme = getThemeFromUrl()
  const productConfig = getProductFromUrl()
  const product = {
    title: productConfig?.name,
  }
  return <Component product={product} theme={theme} {...props} />
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)

const userConnect = connect(state => {
  const { userAccessManager } = state || {}
  const { claims } = userAccessManager || {}
  const { userProfileManager } = state || {}
  const { profile } = userProfileManager || {}
  const { firstName = '', lastName = '', roles = [] } = profile || {}
  return {
    roles,
    userClaims: claims,
    name: `${firstName} ${lastName}`.trim(),
  }
})

NavigationContainer.propTypes = {
  requestRemount: PropTypes.func,
  subNavData: SubNavGetterComponentPropTypes,
}

const subNavGetterOptions = {
  app: () => resolveRouteServiceApp(window.location.pathname),
}

export default compose(
  navigationConnect,
  userConnect,
)(SubNavGetter(NavigationContainer, subNavGetterOptions))
