import { FlagsComponentRouter } from 'flags'

import { isHighSchoolUrl } from 'utils/highschoolHelpers'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['middle-school-june-release', 'high-school-june-release'],
  OnComponent,
  OffComponent,
  onCondition: ({ match }) =>
    match?.params?.productName === 'middle-school' || isHighSchoolUrl(),
})

export default FlagToggler
