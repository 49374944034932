export const ProductType = {
  BPU: { name: 'Bullying Prevention Unit', skuCode: 'BPU' },
  CPU: { name: 'Child Protection Unit', skuCode: 'CPU' },
  K5: { name: 'Elementary', skuCode: 'K5P' },
  MSP: { name: 'Middle School', skuCode: 'MSP' },
  OST: { name: 'Out-of-School Time', skuCode: 'OST' },
  SSP: { name: 'Second Step Program K-5', skuCode: 'SSP' },
  SSPEL: { name: 'Early Learning', skuCode: 'SSPEL' },
}

// THIS IS THE OFFICIAL PRODUCTION TEACH
// TODO [LEARN-8585]: remove skuCode and productType mapping from learn
export const admin = {
  defaultPage: 'implementation-guide',
  name: 'Admin',
  themeName: 'platform',
}

export const district = {
  defaultPage: 'supports',
  name: 'District Supports',
  productName: 'district-supports',
  themeName: 'platform',
}

export const elementary = {
  alternateNames: ['grades-k-5'],
  defaultPage: 'teach',
  entryPoint: '3DURyazBws2DBVtEjYUPUR',
  lmsKey: 'Elementary',
  lmsKeyV2: 'Elementary',
  name: 'Elementary',
  productName: 'elementary',
  skuCode: ProductType.K5.skuCode,
  themeName: 'elementary',
}

export const familyMindYeti = {
  defaultPage: 'playlists-and-supports',
  name: 'Mind Yeti',
  themeName: 'mindyeti',
}

export const familyResources = {
  defaultPage: 'elementary',
  name: 'Family Resources',
  themeName: 'familyResources',
}

export const familyResourcesHS = {
  defaultPage: 'high-school',
  name: 'Family Resources',
  themeName: 'familyResources',
}

export const middleSchool = {
  alternateNames: ['grades-6-8'],
  defaultPage: 'teach',
  entryPoint: '6V4P1pYzF8TMGKzNcPNG9B',
  lmsKey: 'Classes',
  lmsKeyV2: 'MiddleSchool',
  name: 'Middle School',
  productName: 'middle-school',
  skuCode: ProductType.MSP.skuCode,
  themeName: 'middleSchool',
}

export const mindYeti = {
  defaultPage: 'mind-yeti',
  entryPoint: '54apwXMO0wRboRsiy1KIvh',
  name: 'Mind Yeti',
  productName: 'mind-yeti',
  skuCode: null,
  themeName: 'mindyeti',
}

export const reports = {
  name: 'Reports',
  themeName: 'platform',
}

export const selForAdults = {
  defaultPage: 'modules',
  entryPoint: '5c2NEfz76UcLUugDvS8AOo',
  name: 'SEL for Adults',
  productName: 'sel-for-adults',
  skuCode: null,
  themeName: 'sela',
}
export const selForAdultsFieldTest = {
  defaultPage: 'modules',
  entryPoint: 'd9i22xTOiLdwnILB0iUSn',
  name: 'SEL for Adults Field Test',
  productName: 'sel-for-adults-field-test',
  skuCode: null,
  themeName: 'sela',
}

export const highSchool = {
  defaultPage: 'pathways',
  entryPoint: '4jWxfsChj2S6ZgIyzA2ACF',
  name: 'High School',
  productName: 'high-school',
  skuCode: 'HSP',
  themeName: 'highschool',
}

// TODO: All of these should be removed once Planet EXPRESS dynamically adds top level keys to redux

export const k5Kit = {
  alternateNames: ['Second Step Elementary'],
  defaultPage: 'training',
  entryPoint: '7wz0n0pOvPPvF7lidBqyJa',
  name: 'Elementary Classroom Kits',
  productName: 'k5kit',
  skuCode: ProductType.SSP.skuCode,
  themeName: 'k5kit',
}

export const bpuKit = {
  defaultPage: 'training',
  entryPoint: '2ttriSkjEXDpDBnaG246Mt',
  name: 'Bullying Prevention Unit',
  productName: 'bpu',
  skuCode: ProductType.BPU.skuCode,
  themeName: 'bpu',
}

export const cpuKit = {
  defaultPage: 'training',
  entryPoint: '3RkEFGHWSJE1bWzdZFyKwN',
  name: 'Child Protection Unit',
  productName: 'cpu',
  skuCode: ProductType.CPU.skuCode,
  themeName: 'cpu',
}

export const elKit = {
  entryPoint: '3jbBI2DbMy17Eh4eHF4dN7',
  name: 'Early Learning',
  productName: 'elkit',
  skuCode: ProductType.SSPEL.skuCode,
  themeName: 'elkit',
}

export const msKit = {
  alternateNames: ['Grades 6–8 Classroom Kits'],
  defaultPage: 'teach',
  entryPoint: '5xTGruXnEfVhnJW2ztEMaW',
  name: 'Middle School Kit',
  productName: 'mskit',
  skuCode: ProductType.MSP.skuCode,
  themeName: 'mskit',
}

export const outOfSchoolTime = {
  defaultPage: 'program-supports',
  entryPoint: 'C1bnxN0N5hhttOeJeF81e',
  name: 'Out-of-School Time',
  productName: 'ost',
  skuCode: ProductType.OST.skuCode,
  themeName: 'ostKit',
}

export const outOfSchoolTimeK1 = {
  defaultPage: 'program-supports',
  entryPoint: 'C1bnxN0N5hhttOeJeF81e',
  name: 'Out-of-School Time K1',
  productName: 'ost',
  skuCode: ProductType.OST.skuCode,
  themeName: 'ostKitK1',
}

export const outOfSchoolTime23 = {
  defaultPage: 'program-supports',
  entryPoint: 'C1bnxN0N5hhttOeJeF81e',
  name: 'Out-of-School Time 23',
  productName: 'ost',
  skuCode: ProductType.OST.skuCode,
  themeName: 'ostKit23',
}

export const outOfSchoolTime45 = {
  defaultPage: 'program-supports',
  entryPoint: 'C1bnxN0N5hhttOeJeF81e',
  name: 'Out-of-School Time 45',
  productName: 'ost',
  skuCode: ProductType.OST.skuCode,
  themeName: 'ostKit45',
}

// this default export is used to map the route (eg "bpu-kit") to a product
export default {
  admin,
  bpuKit,
  cpuKit,
  district,
  elKit,
  elementary,
  familyMindYeti,
  familyResources,
  familyResourcesHS,
  // hack, point this entry at high school because we query it by url path:
  family: familyResourcesHS,
  k5Kit,
  middleSchool,
  mindYeti,
  msKit,
  outOfSchoolTime,
  outOfSchoolTimeK1,
  outOfSchoolTime23,
  outOfSchoolTime45,
  reports,
  selForAdults,
  selForAdultsFieldTest,
  highSchool,
}

export const RouteContentProductAppMap = {
  learn: 'learnProduct',
  learnOrphans: 'learnOrphans',
  learnFamilyResourcesHS: 'learnFamilyResourcesHS',
  learnFamilyResourcesK8: 'learnFamilyResourcesK8',
}

export const OptionalUnits = {
  BullyingHarassmentUnit: {
    slug: 'recognizing-bullying-and-harassment',
    // FIX: Hopefully its temporary and there will be a better to identify H&B lessons in Course Manager.
    courseNodeIds: [
      'X5S6Sit9WKHgMRbXYQOs1',
      '2H6iYvNCaE86l3RBvbIr3O',
      '33Gc0lwEcMNAtcE2a2Znkh',
    ],
  },
}

// TODO: This is a mapping for the new Contentful ids for Middle School and is a hack to override old Contentful Ids until Program Flex is fully implemented
export const middleSchoolV2ContentfulMappings = [
  {
    displayTitle: 'Grade 6',
    internalTitle: 'Grade 6, MSdigital',
    id: '3MGuMC1rZH4HDF3MNKj3b4',
  },
  {
    displayTitle: 'Grade 7',
    internalTitle: 'Grade 7, MSdigital',
    id: '1k9vPryVAuk0XG4pU9IwnI',
  },
  {
    displayTitle: 'Grade 8',
    internalTitle: 'Grade 8, MSdigital',
    id: '5mhIMAgQUP2RgAxfC3ucyD',
  },
]
