import React from 'react'
import PropTypes from 'prop-types'
import K8DigitalProgramCard from './component'
import K8DigitalProgramCardV2 from 'components/dashboard/K8DigitalProgramCardV2'

const K8DigitalProgramCardContainer = props => {
  // TODO: remove this container when june release for ms/hs is complete
  return (
    <>
      {props.isK5Product && <K8DigitalProgramCard {...props} />}
      {props.isMsProduct && <K8DigitalProgramCardV2 {...props} />}
    </>
  )
}

K8DigitalProgramCardContainer.propTypes = {
  isK5Product: PropTypes.bool,
  isMsProduct: PropTypes.bool,
}

export default K8DigitalProgramCardContainer
