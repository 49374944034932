import React from 'react'
import PropTypes from 'prop-types'
import AdvisoryActivitiesHeader from 'components/AdvisoryActivitiesHeader'
import ContentCardList from 'experienceComponents/ContentCardList'
import { contentfulImageShape } from 'utils/shapes'
import { AdvistoryActivitiesPageWrapper } from './component.styles'
import { getProductType } from './helper'

const AdvisoryActivitiesLanding = ({
  contentOverview,
  contentCardList,
  descriptionImage,
  descriptionPdf,
  entryId,
  getEntryWithNewLocale,
  handleContentChange,
  languageToggle,
  locale,
  location,
  navigationEvent,
}) => {
  const contentCardListKey = 'content-card-list'
  return (
    <AdvistoryActivitiesPageWrapper data-testid="activities-landing-page">
      <AdvisoryActivitiesHeader
        activeContent={contentCardList?.activeContent}
        activeContentType={contentCardList?.activeContentType}
        contentOverview={contentOverview}
        contentTypes={contentCardList?.contentTypes}
        entryId={entryId}
        getProductType={getProductType}
        handleContentChange={handleContentChange}
        locale={locale}
      />
      <ContentCardList
        {...contentCardList}
        descriptionImage={descriptionImage}
        descriptionPdf={descriptionPdf}
        getEntryWithNewLocale={getEntryWithNewLocale}
        getProductType={getProductType}
        handleContentChange={handleContentChange}
        key={contentCardListKey}
        languageToggle={languageToggle}
        locale={locale}
        location={location}
        navigationEvent={navigationEvent}
      />
    </AdvistoryActivitiesPageWrapper>
  )
}

AdvisoryActivitiesLanding.propTypes = {
  contentCardList: PropTypes.object.isRequired,
  contentOverview: PropTypes.object.isRequired,
  descriptionImage: contentfulImageShape,
  descriptionPdf: PropTypes.array,
  entryId: PropTypes.string,
  getEntryWithNewLocale: PropTypes.func,
  handleContentChange: PropTypes.func.isRequired,
  languageToggle: PropTypes.object,
  locale: PropTypes.string,
  location: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func.isRequired,
}

export default AdvisoryActivitiesLanding
