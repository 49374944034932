import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'
import { isMiddleSchoolProductJuneRelease } from 'utils/productHelpers'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['middle-school-june-release'],
  OnComponent,
  OffComponent,
  onCondition: isMiddleSchoolProductJuneRelease,
})

export default FlagToggler
