import LessonDetailsPage from 'pages/LessonDetailsPage'
import LessonDetailsElPage from 'pages/LessonDetailsElPage'
import StreamingLessonMediaPage from 'pages/StreamingLessonMediaPage'
import ResourcesLanding from 'pages/ResourcesLanding'
import ResourcesSubLandingPage from 'pages/ResourcesSubLandingPage'
import ResourcesContent from 'pages/ResourcesContent'
import AdvisoryActivitiesLandingContainer from 'pages/AdvisoryActivitiesLanding'
import AdvisoryActivitiesContent from 'pages/AdvisoryActivitiesContent'
import CourseDetailsPageContainer from 'pages/CourseDetailsPage'
import MindYetiPlaylistContainer from 'pages/MindYeti/Playlist'
import HsResourceLibrary from 'pages/HsResourceLibrary'
import ImplementationGuide from 'pages/ImplementationGuide'
import Pathways from 'pages/Pathways'
import Pathway from 'pages/Pathway'
import Week from 'pages/Week'

import {
  SLM_LESSON_CONTENT_TYPE,
  SLM_LESSON_EL_CONTENT_TYPE,
  SLM_CONTENT_TYPE,
  SUBSECTION_LANDING_CONTENT_TYPE,
  PROGRAM_SECTION_ARTICLE_TREE_CONTENT_TYPE,
  ARTICLE_CONTENT_TYPE,
  ACTIVITY_LIST_CONTENT_TYPE,
  GROUPED_CONTENT_CONTENT_TYPE,
  ADVISORY_ACTIVITY_CONTENT_TYPE,
  SLM_COURSE_CONTENT_TYPE,
  TEACH_SECTION,
  PLAYLIST_TYPE,
  PLAYIST_TAB_TYPE,
  RESOURCE_LIBRARY,
  STATIC_LANDING_PAGE_TYPE,
  HS_COURSE, // A/K/A "Pathways"
  HS_UNIT,
  WEEK,
} from './constants'

export default {
  [ACTIVITY_LIST_CONTENT_TYPE]: AdvisoryActivitiesLandingContainer,
  [ADVISORY_ACTIVITY_CONTENT_TYPE]: AdvisoryActivitiesContent,
  [ARTICLE_CONTENT_TYPE]: ResourcesContent,
  [GROUPED_CONTENT_CONTENT_TYPE]: AdvisoryActivitiesLandingContainer,
  [PLAYIST_TAB_TYPE]: MindYetiPlaylistContainer,
  [PLAYLIST_TYPE]: MindYetiPlaylistContainer,
  [PROGRAM_SECTION_ARTICLE_TREE_CONTENT_TYPE]: ResourcesLanding,
  [SLM_CONTENT_TYPE]: StreamingLessonMediaPage,
  [SLM_COURSE_CONTENT_TYPE]: CourseDetailsPageContainer,
  [SLM_LESSON_CONTENT_TYPE]: LessonDetailsPage,
  [SLM_LESSON_EL_CONTENT_TYPE]: LessonDetailsElPage,
  [STATIC_LANDING_PAGE_TYPE]: ImplementationGuide,
  [SUBSECTION_LANDING_CONTENT_TYPE]: ResourcesSubLandingPage,
  [TEACH_SECTION]: CourseDetailsPageContainer,
  [RESOURCE_LIBRARY]: HsResourceLibrary,
  [HS_COURSE]: Pathways,
  [HS_UNIT]: Pathway,
  [WEEK]: Week,
}
