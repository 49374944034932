import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AdminTrainingEmptyStateIcon } from 'secondstep-components'
import { domains } from 'config/env'
import NavigationHelper from 'layers/navigation/navigationHelper'
import PacingModal from 'components/PacingModal'
import StepModal from 'components/StepModal'
import {
  COMING_SOON_TEXT_BOLD,
  COMING_SOON_TEXT_REGULAR,
  EDIT_LINK_TEXT,
  HS_TRAINING_URL,
  MANAGE_USERS_LINK_TEXT,
  MANAGE_USERS_URL,
  PACING_SCHEDULE_LINK_TEXT,
  PROGRAM_TRAINING_BUTTON_TEXT,
  SCHOOL_SETTINGS_TEXT,
  USERS_ADDED_TEXT,
} from './constants'
import {
  ActiveCount,
  BodyBox,
  BodyText,
  Bullet,
  CountBox,
  CountRow,
  EditLink,
  LeftBox,
  ManageUsersLink,
  PacingDescriptionBox,
  PacingScheduleLink,
  PacingText,
  PendingCount,
  RightBox,
  SchoolSettingsBox,
  SchoolSettingsText,
  StyledError,
  StyledHairline,
  StyledOutlineButton,
  StyledWarningIcon,
  TotalCount,
  UsersAddedBox,
  UsersAddedCount,
  UsersAddedText,
} from './component.styles'

const HighSchoolLaunchSnapshot = ({
  navigationEvent,
  programKey,
  site,
  sitePreferences,
}) => {
  const [isHighSchholLaunchedError, setIsHighSchholLaunchedError] = useState(
    false,
  )
  const [isPacingModalOpen, setPacingModalOpen] = useState(false)
  const [isStepModalOpen, setStepModalOpen] = useState(false)

  const navigateTo = url => {
    const isManageUsersUrl = url === MANAGE_USERS_URL
    const type = isManageUsersUrl
      ? NavigationHelper.types.OPEN
      : NavigationHelper.types.PUSH
    const path = `${isManageUsersUrl ? domains.ADMIN_DOMAIN : ''}${url}${
      isManageUsersUrl ? `/${site.siteId}` : ''
    }`

    navigationEvent(path, type)
  }
  const togglePacingModal = () => {
    setPacingModalOpen(!isPacingModalOpen)
  }
  const toggleStepModal = () => {
    setStepModalOpen(!isStepModalOpen)
  }

  useEffect(() => {
    if (sitePreferences.error && sitePreferences.siteId === site.siteId) {
      setIsHighSchholLaunchedError(true)
    }
  }, [sitePreferences])

  return (
    <BodyBox>
      <LeftBox>
        <SchoolSettingsBox>
          <SchoolSettingsText>{SCHOOL_SETTINGS_TEXT}</SchoolSettingsText>
          <StyledHairline />
          <PacingDescriptionBox>
            <PacingText>{`Level ${site.preferences.implementationLevel} • ${site.preferences.implementationType}`}</PacingText>
            <EditLink onClick={toggleStepModal}>{EDIT_LINK_TEXT}</EditLink>
          </PacingDescriptionBox>
          <PacingScheduleLink onClick={togglePacingModal}>
            {PACING_SCHEDULE_LINK_TEXT}
          </PacingScheduleLink>
        </SchoolSettingsBox>
        <UsersAddedBox>
          <UsersAddedCount>{site.totalUsersCount}</UsersAddedCount>
          <UsersAddedText>{USERS_ADDED_TEXT}</UsersAddedText>
          <StyledHairline />
          <TotalCount>
            {`${site.adminsActiveCount +
              site.adminsPendingCount} Administrators`}
          </TotalCount>
          <CountRow>
            <CountBox>
              <Bullet />
              <ActiveCount>{`${site.adminsActiveCount} active`}</ActiveCount>
            </CountBox>
            <CountBox>
              <Bullet color="yellow" />
              <PendingCount>
                {`${site.adminsPendingCount} pending`}
              </PendingCount>
            </CountBox>
          </CountRow>
          <TotalCount>
            {`${site.teachersActiveCount + site.teachersPendingCount} Teachers`}
          </TotalCount>
          <CountRow>
            <CountBox>
              <Bullet />
              <ActiveCount>{`${site.teachersActiveCount} active`}</ActiveCount>
            </CountBox>
            <CountBox>
              <Bullet color="yellow" />
              <PendingCount>
                {`${site.teachersPendingCount} pending`}
              </PendingCount>
            </CountBox>
          </CountRow>
          <ManageUsersLink onClick={() => navigateTo(MANAGE_USERS_URL)}>
            {MANAGE_USERS_LINK_TEXT}
          </ManageUsersLink>
        </UsersAddedBox>
      </LeftBox>
      <RightBox>
        <AdminTrainingEmptyStateIcon />
        <BodyText>
          <span>{COMING_SOON_TEXT_BOLD}</span> {COMING_SOON_TEXT_REGULAR}
        </BodyText>
        <StyledOutlineButton onClick={() => navigateTo(HS_TRAINING_URL)}>
          {PROGRAM_TRAINING_BUTTON_TEXT}
        </StyledOutlineButton>
      </RightBox>
      {isStepModalOpen && (
        <StepModal
          editMode={true}
          handleClose={toggleStepModal}
          programKey={programKey}
          savedPreferences={site.preferences}
          siteId={site.siteId}
        />
      )}
      {isPacingModalOpen && (
        <PacingModal
          handleClose={togglePacingModal}
          programKey={programKey}
          savedPreferences={site.preferences}
          siteId={site.siteId}
        />
      )}
      {isHighSchholLaunchedError && (
        <StyledError>
          <StyledWarningIcon />
          Something went wrong.
          <br />
          Try again.
        </StyledError>
      )}
    </BodyBox>
  )
}

HighSchoolLaunchSnapshot.propTypes = {
  navigationEvent: PropTypes.func,
  programKey: PropTypes.string,
  site: PropTypes.object,
  sitePreferences: PropTypes.object,
}

export default HighSchoolLaunchSnapshot
