import PropTypes from 'prop-types'
import React from 'react'
import Notification from 'components/Notification'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import LessonList from 'experienceComponents/LessonList'
import RecommendedAdvisoryActivities from 'experienceComponents/RecommendedAdvisoryActivities'
import UnitOverview from 'experienceComponents/UnitOverview'
import UnitTabSet from 'experienceComponents/UnitTabSet'
import VideoCallout from 'experienceComponents/VideoCallout'
import {
  AcknowledgementsLink,
  UnitComponentWrapper,
  UnitOverviewBox,
} from './component.styles'
import UnitComponentEvents from './events'
import { getData } from './data'
import { LMSUnitType, UnitType } from '../types'

const UnitComponent = props => {
  const {
    acknowledgements,
    match,
    navigationEvent,
    onSelectUnit,
    productName,
    unit,
    unitId,
    lmsUnit,
  } = props

  if (!unit) {
    return (
      <Notification color="danger">
        <h3>{`Unit ${match?.params?.unit ?? 'unknown'} not found`}</h3>
      </Notification>
    )
  }

  const {
    advisoryActivities,
    lessonStartingIndex,
    unitVideo,
    unitName,
    lmsLessons,
    unitTabs,
  } = getData({ contentUnit: unit, currentUnitId: unitId, lmsUnit })
  const showAdditionalResources = advisoryActivities.size === 3
  const gradeTheme = match.params?.course
    ? match.params.course.replace('-', '')
    : ''

  return (
    <LearnErrorBoundary>
      <UnitComponentWrapper animation={'fadeIn'}>
        <UnitTabSet
          ariaControls={'unit-overviewbox'}
          match={match}
          onSelect={onSelectUnit}
          selectedUnitSlug={unitId}
          units={unitTabs}
        />
        <UnitOverviewBox id="unit-overviewbox" role={'tabpanel'}>
          <UnitOverview
            contentfulItem={unit}
            dataTestId={`unit-overview-${unitId}`}
            tabIndex={0}
          />
          {unitVideo && (
            <VideoCallout
              dataTestId={`video-callout-${unitId}`}
              id="video-callout"
              locale={'en-US'}
              videoDisplayTitle={unitVideo.displayTitle}
              videoId={unitVideo.id}
              videoLength={unitVideo.time}
              videoThumbnailUrl={unitVideo.videoReference.posterImage?.url}
            />
          )}
        </UnitOverviewBox>
        <LessonList
          contentfulLessons={unit.lessons}
          dataTestId="lesson-list"
          gradeTheme={gradeTheme}
          lessonStartingIndex={lessonStartingIndex}
          lessons={lmsLessons}
          match={match}
          unitResources={unit.unitResources}
        />
        {showAdditionalResources && (
          <RecommendedAdvisoryActivities
            contentfulItem={unit}
            match={match}
            productName={productName}
            unitName={unitName}
          />
        )}
      </UnitComponentWrapper>

      {acknowledgements && (
        <AcknowledgementsLink
          acknowledgementsDataNOTCONNECTED={acknowledgements}
          dataTestId="acknowledgements-link"
          onClick={UnitComponentEvents.onClickAcknowledgements({
            match,
            acknowledgements,
            navigationEvent,
          })}
        >
          Acknowledgements
        </AcknowledgementsLink>
      )}
    </LearnErrorBoundary>
  )
}

UnitComponent.propTypes = {
  acknowledgements: PropTypes.object,
  lmsUnit: LMSUnitType,
  match: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  onSelectUnit: PropTypes.func.isRequired,
  productName: PropTypes.string,
  unit: UnitType,
  unitId: PropTypes.string,
}

UnitComponent.displayName = 'UnitComponent'

export default UnitComponent
