import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SNAPSHOTS_NAME_MAP } from 'utils/adminExperienceHelpers'
import { ADMIN_DASHBOARD_ENTRY_ID } from 'utils/entryIds'
import { findTooltipByLocation } from 'utils/tooltipHelpers'
import AdminTooltip from './component'

const AdminTooltipContainer = ({
  className,
  dataTestId,
  iconClass,
  label,
  monitorView,
  tooltipData,
}) => {
  const TooltipText = tooltip => {
    return (
      <>
        <span className="tooltip-header">{tooltip?.tooltipHeader}</span>
        <span>{tooltip?.text}</span>
      </>
    )
  }

  return (
    <AdminTooltip
      className={className}
      content={TooltipText(tooltipData)}
      dataTestId={dataTestId}
      iconClass={iconClass}
      label={label}
      monitorView={monitorView}
    />
  )
}

const mapStateToProps = (
  { contentful },
  { location, panelName, programKey },
) => {
  const snapshots =
    contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload?.[
      SNAPSHOTS_NAME_MAP?.[programKey]
    ]
  const tooltipData = findTooltipByLocation(
    location,
    programKey,
    snapshots,
    panelName,
  )

  return {
    tooltipData,
  }
}

AdminTooltipContainer.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  iconClass: PropTypes.string,
  label: PropTypes.string,
  location: PropTypes.string,
  monitorView: PropTypes.bool,
  programKey: PropTypes.string,
  tooltipData: PropTypes.object,
}

export default connect(mapStateToProps, null)(AdminTooltipContainer)
