import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import AdminHSSingleSiteView from './component'

export const AdminHSSingleSiteContainer = ({
  adminEmail,
  fetchHsLaunchSnapshot,
  selectedProgramKey,
  site,
}) => {
  useEffect(() => {
    if (!site) {
      fetchHsLaunchSnapshot(adminEmail)
    }
  }, [site])

  if (!site) {
    return null
  }

  return <AdminHSSingleSiteView programKey={selectedProgramKey} site={site} />
}

const mapStateToProps = state => {
  const {
    reportsManager: {
      selectedProgram: { key: selectedProgramKey },
    },
    userProfileManager: {
      profile: { email: adminEmail },
    },
  } = state
  // TODO: this component is tied to launch context, refactor using context provider or hoc
  const hsSites = reportsSelectors.selectAdminHsLaunchSnapshotSites(state)
  return {
    adminEmail,
    selectedProgramKey,
    site: hsSites?.[0],
  }
}

const mapDispatchToProps = {
  fetchHsLaunchSnapshot: reportsOperations.fetchHsLaunchSnapshot,
}

AdminHSSingleSiteContainer.propTypes = {
  adminEmail: PropTypes.string,
  fetchHsLaunchSnapshot: PropTypes.func,
  selectedProgramKey: PropTypes.string,
  site: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string,
        siteId: PropTypes.number,
        users: PropTypes.array,
        preferences: PropTypes.shape({
          areUsersAdded: PropTypes.bool,
          implementationLevel: PropTypes.number,
          implementationType: PropTypes.string,
          isLaunched: PropTypes.bool,
          isSetup: PropTypes.bool,
          studentActivities: PropTypes.bool,
        }),
        adminsPendingCount: PropTypes.number,
        teachersPendingCount: PropTypes.number,
        totalUsersCount: PropTypes.number,
      }),
    ), // fully hydrated
    PropTypes.object, // fetching or error
    PropTypes.oneOf([null]), // not hydrated
  ]),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminHSSingleSiteContainer, [ERROR_TYPE_REPORTING]))
