import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GlobalNav from 'components/GlobalNav'
import ProgramNav from 'components/ProgramNav'
import { SubNavGetterComponentPropTypes } from 'layers/content/v2/Hocs/SubNavGetter'

import { SETUP_ADMIN } from './constants'

export default class NavigationLayout extends Component {
  static propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }),
    name: PropTypes.string,
    navigationEvent: PropTypes.func.isRequired,
    product: PropTypes.shape({
      product: PropTypes.string.isRequired,
    }),
    roles: PropTypes.array,
    subNavData: SubNavGetterComponentPropTypes,
    theme: PropTypes.object,
    userClaims: PropTypes.object,
  }

  transformSubNavDataToPrograms(subNavData) {
    return (
      // keep the prop shape the same as it was in the old system for the ProgramNav component
      subNavData?.map(({ displayTitle, path }) => ({
        title: displayTitle,
        route: path,
        isSelected: location.pathname.includes(path),
      })) || []
    )
  }

  render() {
    const {
      roles,
      userClaims,
      navigationEvent,
      product,
      location,
      theme,
      name,
      subNavData,
    } = this.props
    const isSetUpAdmin = roles?.includes(SETUP_ADMIN)
    const {
      hasElemAdminClaim,
      hasHighschoolAdminClaim,
      hasMsAdminClaim,
      hasSelaAdminClaim,
    } = userClaims || {}
    const hasAdminClaim =
      hasElemAdminClaim ||
      hasHighschoolAdminClaim ||
      hasMsAdminClaim ||
      hasSelaAdminClaim

    return (
      <React.Fragment>
        <GlobalNav
          hasAdminClaim={hasAdminClaim}
          isSetUpAdmin={isSetUpAdmin}
          name={name}
          navigationEvent={navigationEvent}
          pathname={location.pathname}
          product={product}
          theme={theme}
        />
        <ProgramNav
          goToProgram={navigationEvent}
          location={location}
          programs={this.transformSubNavDataToPrograms(subNavData.data)}
        />
      </React.Fragment>
    )
  }
}
