import { Accordion, ProgressBarIndicator } from 'secondstep-components'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

const breakpoints = {
  customTablet: '@media screen and (max-width: 860px)',
}

export const Bullet = styled.span.attrs(props => ({
  background:
    props.color === 'yellow'
      ? themeGet('colors.yellow')(props)
      : themeGet('colors.lightOrange')(props),
}))`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.375rem;
  border-radius: 50%;
  background: ${({ background }) => background};
  transform: translateY(0.0938rem);
`

export const CollapsibleListItem = styled(Accordion).attrs(
  ({ dataTestId, totalPendingCount }) => ({
    padding:
      totalPendingCount === 0
        ? '0.9125rem 0.25rem 0.9125rem 1.125rem'
        : '0.6875rem 0.25rem 0.6875rem 1.125rem',
    'data-testid': dataTestId,
  }),
)`
  width: 100%;
  border: 2px solid
    ${themeGet('colors.backgrounds.secondary', 'colors.paleGray')};
  background: ${themeGet('colors.paleGray')};

  &:hover {
    outline: 2px solid ${themeGet('colors.grayBlue')};
  }

  [class*='AccordionPanel-sc'] {
    [class*='ColumnWrapper-sc'] {
      [class*='Bullet-sc'] {
        background-color: ${themeGet('colors.yellow')};
      }
    }
    padding: ${({ padding }) => padding};
    background: ${themeGet('colors.paleGray')};

    ${breakpoints.customTablet} {
      padding: 0.875rem 1.125rem;
    }

    &:hover {
      svg {
        color: ${themeGet('colors.darkBlue')};
      }
    }
  }

  [class*='AccordionTitleWrapper'] {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  [class*='AccordionTitle'] {
    width: 100%;
    margin-right: 0;
  }

  [class*='AccordionContentWrapper'] {
    width: 100%;
    margin: 0;
    overflow: visible;
    [class*='AccordionContent'] {
      padding: 0 1rem 1.25rem 1rem;
      background: ${themeGet('colors.paleGray')};
    }
  }

  [class*='StyledCaret'] {
    &:hover {
      color: ${themeGet('colors.darkBlue')};
    }
  }
`

export const ColumnCopy = styled.div`
  font-family: ${themeGet('font.molde.medium')};
  padding-left: 1.5rem;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: normal;
  color: ${themeGet('colors.darkGray')};
  gap: 0.5rem;
  flex: 1;
`

export const EducatorPracticesColumn = styled(ColumnCopy)`
  padding-left: 2.5rem;

  ${breakpoints.customTablet} {
    padding-left: 48px;
  }
`

export const NameWrapper = styled.span`
  font-size: 1.125rem;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkBlue')};
  height: 100%;
  line-height: 1.3;
  flex: 1;
`

export const PanelWrapper = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  width: 100%;
`

export const PendingCount = styled.span`
  padding-left: 1.375rem;
`

export const PacingColumn = styled(ColumnCopy)`
  padding-left: 0.6875rem;
`

export const SchoolwidePracticesColumn = styled(ColumnCopy)`
  padding-left: 1.6875rem;

  ${breakpoints.customTablet} {
    padding-left: 2rem;
  }
`

export const StaffTrainingCompletionColumn = styled(ColumnCopy)`
  padding-left: 2.875rem;
`

export const StudentActivitiesColumn = styled(ColumnCopy)`
  padding-left: 3.4375rem;

  ${breakpoints.customTablet} {
    padding-left: 4.0625rem;
  }
`

export const StyledProgressBarIndicator = styled(ProgressBarIndicator)`
  [class*='Background'] {
    background-color: ${themeGet('colors.linkLight')};
    height: 0.75rem;
    margin-top: 0.125rem;
    max-width: 150px;
  }

  [class*='BodyCopy'] {
    padding: 0.1875rem 0 0 0.5rem;
  }

  [class*='IndicatorWrapper'] {
    height: 0.75rem;

    [class*='Indicator'] {
      background-color: ${themeGet('colors.hsPrimaryPink')};
      border-color: ${themeGet('colors.darkBlue')};
      height: 0.75rem;
    }
  }
`
