import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Tooltip, TooltipContentWrapper } from 'secondstep-components'

export const StyledTooltip = styled(Tooltip).attrs(({ monitorView }) => ({
  width: monitorView ? '195px' : '211px',
}))`
  width: auto;
  margin-left: 0.5rem;

  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }

  span {
    display: block;
    line-height: 1.15rem;
  }

  .tooltip-header {
    margin-bottom: 0.125rem;
    font-family: ${themeGet('font.molde.semiBold')};
  }

  ${TooltipContentWrapper} {
    top: -112px;
    left: 48px;
    width: ${({ width }) => width};
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    color: ${themeGet('colors.gray675')};
    text-align: left;
    text-wrap: wrap;
    line-height: 1rem;

    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 43%;
      left: 76px;
      background-color: white;
      transform: translateY(400%) rotate(45deg);
    }
  }
`
