import React from 'react'
// import PropTypes from 'prop-types'
import AdminHsMonitorSnapshot from 'components/AdminHsMonitorSnapshot'
import { MonitorViewWrapper } from './component.styles'

const AdminHSMonitorView = () => (
  <MonitorViewWrapper>
    <AdminHsMonitorSnapshot />
  </MonitorViewWrapper>
)

export default AdminHSMonitorView
